import React, { FC } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { useEntity } from 'icerockdev-admin-toolkit';
import PlacementEntity from '~/pages/placement/PlacementEntity';
import StarIcon from '@material-ui/icons/Star';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';

export interface SpecialOfferButtonProps extends IEntityFieldProps {
  view: 'text' | 'icon';
}
export const SpecialOfferButton: FC<SpecialOfferButtonProps> = ({ data, view }) => {
  const entity = useEntity<PlacementEntity>();

  const addSpecialOfferText = 'Добавить в спецпредложения';
  const deleteSpecialOfferText = 'Убрать из спецпредложений';
  const handleClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (data?.specialOffer && data?.id) {
      await entity.deleteSpecialOffer(data?.id);
    } else {
      await entity.addSpecialOffer(data?.id);
    }
  };

  if (view === 'icon') {
    return (
      <Tooltip title={`${data?.specialOffer ? deleteSpecialOfferText : addSpecialOfferText}`}>
        <Button onClick={handleClick}>
          {data?.specialOffer ? (
            <StarIcon color={'primary'} />
          ) : (
            <StarBorderOutlinedIcon color={'primary'} />
          )}
        </Button>
      </Tooltip>
    );
  }

  if (view === 'text') {
    return (
      <Button
        onClick={handleClick}
        variant={'contained'}
        color={data?.specialOffer ? 'secondary' : 'primary'}
      >
        {data?.specialOffer ? deleteSpecialOfferText : addSpecialOfferText}
      </Button>
    );
  }

  return null;
};
