import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const DEFAULT_THEME: ThemeOptions = {
  palette: {
    primary: {
      main: '#3f51b5',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
  },
  shape: {
    borderRadius: 4,
  },
};
