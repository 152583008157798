import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import EntityImage from '~/common/components/EntityImage';
import { BOOKING_STATUS_NAMES, BOOKING_TYPE_NAMES, StayUnitKind } from '~/pages/constants';

export const BOOKING_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    sortable: true,
  },
  {
    type: 'string',
    name: 'bookingNumber',
    label: 'Номер бронирования',
  },
  {
    type: 'select',
    name: 'type',
    label: 'Тип',
    options: BOOKING_TYPE_NAMES,
    filterable: true,
    hideInView: true,
  },
  {
    type: 'custom',
    component: ({ value }) => {
      const partner = value ?? {};
      const { firstName, lastName } = partner;
      return (
        <>
          {[firstName, lastName]
            .filter(Boolean)
            .map((u) => u.trim())
            .join(' ')}
        </>
      );
    },
    name: 'guest',
    label: 'Имя туриста',
    sortable: true,
    hideInView: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Название',
    sortable: true,
    hideInView: true,
  },
  {
    type: 'date',
    name: 'bookingDateTime',
    label: 'Дата бронирования',
    filterable: true,
    hideInView: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    options: BOOKING_STATUS_NAMES,
    filterable: true,
  },

  // View fields
  {
    type: 'custom',
    component: ({ value }) => {
      const touristData = value ?? {};
      const { firstName, middleName, lastName, email, phone, comments } = touristData;
      return (
        <>
          <p>
            Имя:{' '}
            {[firstName, middleName, lastName]
              .filter(Boolean)
              .map((u) => u.trim())
              .join(' ')}
          </p>
          <p>E-mail: {email}</p>
          <p>Контактный номер: {phone}</p>
          <p>Вопросы: {comments}</p>
        </>
      );
    },
    name: 'touristData',
    label: 'Данные туриста',
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ data }) => {
      const partnerData = data?.placementData?.partnerData ?? {};
      const { firstName, middleName, lastName, email, phone } = partnerData;
      return (
        <>
          <p>
            Имя:{' '}
            {[firstName, middleName, lastName]
              .filter(Boolean)
              .map((u) => u.trim())
              .join(' ')}
          </p>
          <p>E-mail: {email}</p>
          <p>Контактный номер: {phone}</p>
        </>
      );
    },
    name: 'partnerData',
    label: 'Данные партнера',
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ data }) => (
      <ul>
        <li>Адрес: {data?.placementData?.address}</li>
        <li>
          Координаты: {data?.placementData?.coordinate?.lat}, {data?.placementData?.coordinate?.lng}
        </li>
      </ul>
    ),
    name: 'location',
    label: 'Расположение',
    required: false,
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ data }) => (
      <EntityImage width={200} val={data?.placementData?.images?.[0] ?? ''} />
    ),
    name: 'mainImage',
    label: 'Главное изображение',
    required: false,
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ data }) => <>{data?.placementData?.name ?? ''}</>,
    name: 'name',
    label: 'Название',
    sortable: true,
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ data }) => <>{data?.placementData?.rating ?? ''}</>,
    name: 'rating',
    label: 'Рейтинг',
    sortable: true,
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ data }) => (
      <>
        {data?.placementData?.stayUnitKind === StayUnitKind.NIGHT
          ? 'Ночь'
          : data?.placementData?.stayUnitKind === StayUnitKind.DAY
          ? 'День'
          : 'Неизвестно'}
      </>
    ),
    name: 'stayUnitKind',
    label: 'Тип размещения',
    required: false,
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ data }) => {
      const roomStays = Array.isArray(data?.placementData?.roomStays)
        ? data?.placementData?.roomStays
        : [];
      return (
        <ul>
          {roomStays.map((roomStay) => {
            const plan = roomStay?.ratePlan ?? {};
            return (
              <li>
                <h4>{plan?.name}</h4>
                <p dangerouslySetInnerHTML={{ __html: plan?.description }} />
                <span>{plan?.currency}</span>
              </li>
            );
          })}
        </ul>
      );
    },
    name: 'ratePlan',
    label: 'Тарифныq план',
    required: false,
    hideInList: true,
  },
];
