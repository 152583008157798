import {
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityUpdateFunctionProps,
  IEntityUpdateFunctionResult,
  IEntityUpdateFunction,
} from 'icerockdev-admin-toolkit';
import { uploadFile } from '~/utils/api';
import { axios } from '~/utils/axios';

export const createItemsFn = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const imageKey = data.image ? await uploadFile({ file: data.image, token }) : null;
  const result = await axios.post(
    url,
    {
      name: data.name.trim(),
      isHide: !!data.isHide,
      isCarousel: !!data.isCarousel,
      isPopular: !!data.isPopular,
      timezone: data.timezone,
      imageKey: imageKey,
    },
    {
      headers: { authorization: token ?? '' },
    }
  );

  return { data: result.data.data };
};

export const updateItemsFn: IEntityUpdateFunction = async ({ url, token, data }) => {
  const imageKey = data.image ? await uploadFile({ file: data.image, token }) : null;
  const result = await axios.put(
    `${url}/${data.id}`,
    {
      name: data.name.trim(),
      isHide: !!data.isHide,
      isCarousel: !!data.isCarousel,
      isPopular: !!data.isPopular,
      timezone: data.timezone,
      imageKey: imageKey ?? data.imageKey,
    },
    {
      headers: { authorization: token ?? '' },
    }
  );
  return {
    data: result.data,
  };
};

export const blockItemsFn = async ({
  url,
  token,
  data,
}: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  const result = await axios.put(
    `${url}/${data.id}`,
    {
      name: data.name.trim(),
      isHide: true,
      isCarousel: !!data.isCarousel,
      timezone: data.timezone,
      imageKey: null,
    },
    {
      headers: { authorization: token ?? '' },
    }
  );
  return {
    data: result.data,
  };
};

export const unblockItemsFn = async ({
  url,
  token,
  data,
}: IEntityUpdateFunctionProps): Promise<IEntityUpdateFunctionResult> => {
  const result = await axios.put(
    `${url}/${data.id}`,
    {
      name: data.name.trim(),
      isHide: false,
      isCarousel: !!data.isCarousel,
      timezone: data.timezone,
      imageKey: null,
    },
    {
      headers: { authorization: token ?? '' },
    }
  );
  return {
    data: result.data,
  };
};

const setPopularFn =
  (state: boolean) =>
  async ({
    url,
    token,
    data,
  }: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
    const result = await axios.put(
      `${url}/${data.id}`,
      {
        name: data.name.trim(),
        isHide: !!data.isHide,
        isCarousel: !!data.isCarousel,
        isPopular: state,
        timezone: data.timezone,
        imageKey: data.imageKey,
      },
      {
        headers: { authorization: token },
      }
    );

    return {
      data: result.data,
    };
  };

export const addPopularFn = setPopularFn(true);
export const deletePopularFn = setPopularFn(false);
