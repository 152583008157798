import React, { FC } from 'react';
import { HideShowButton } from '~/pages/placement/components/HideShowButton';
import { SpecialOfferButton } from '~/pages/placement/components/SpecialOfferButton';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';

export interface ButtonContainerProps extends IEntityFieldProps {
  view: 'text' | 'icon';
}

const ButtonContainer: FC<ButtonContainerProps> = (props) => {
  return (
    <>
      <HideShowButton {...props} />
      <SpecialOfferButton {...props} />
    </>
  );
};

export default ButtonContainer;
