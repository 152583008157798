export const API_URLS = {
  auth: {
    signIn: '/v1/auth/sign-in',
    refresh: '/v1/auth/refresh',
    logout: '/v1/auth/logout',
    restore: '/v1/auth/restore',
  },
  city: '/v1/city',
  upload: '/v1/file/upload',
  placement: '/v1/placement',
  booking: '/v1/booking',
  profile: '/v1/user/profile',
  tourist: '/v1/user',
  transaction: '/v1/transaction',
  excursion: '/v1/excursion',
  guide: '/v1/guide',
};

export const ONLY_CYRILLIC_SYMBOLS = /^([а-яА-Я ]{0,500})$/;
export const CITY_NAME_REGEXP = /^([A-Za-zА-Яа-я-() ]{2,100})$/;

export const PHONE_NUMBER_MIN_LENGTH = 6;
export const PHONE_NUMBER_MAX_LENGTH = 20;
export const PHONE_NUMBER_REGEXP = /^[0-9]{6,20}$/;
export const EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Zа-яА-ЯёЁ0-9.-]+\.[a-zA-Zа-яА-ЯёЁ0-9-]{2,}$/;
export const CONTACT_NAME_REGEX =
  /^(?!.*[- ]{2})(?=.*[A-Za-zА-Яа-яёЁ])[A-Za-zА-Яа-яёЁ][A-Za-zА-Яа-яёЁ -]{2,33}$/;
export const REFERENCE_LIMIT = 9999;
export const MAX_IMAGE_SIZE = 1024 * 1024 * 10;
export const ALLOWED_IMAGE_EXTENSIONS = ['image/png', 'image/jpg', 'image/jpeg'];
export const DEFAULT_MIN_STRING_LENGTH = 1;
export const DEFAULT_MAX_STRING_LENGTH = 40;
export const CONTACT_NAME_MIN_LENGTH = 2;
export const CONTACT_NAME_MAX_LENGTH = 100;
export const MIN_COMPANY_NAME = 2;
export const MAX_COMPANY_NAME = 100;
export enum DefaultStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
}

export const DEFAULT_STATUS_NAMES = {
  [DefaultStatus.Active]: 'Активен',
  [DefaultStatus.Blocked]: 'Заблокирован',
};
