import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import { EntityFieldPhone } from '~/common/components/EntityFieldPhone';
import EntityImage from '~/common/components/EntityImage';
import EntityOwnerField from '~/common/components/EntityOwnerField';
import { DURATIONS, GUIDE_STATUS_NAMES, TRAVEL_MODE_TYPE_STATUS_NAMES } from '~/pages/constants';
import GuideEntityButton from '~/pages/guide/components/GuideEntityButton';
import { SpecialOfferButton } from '~/pages/guide/components/SpecialOfferButton';
import { validatePhone } from '~/utils/validators';

export const GUIDE_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    sortable: true,
    hideInEdit: true,
  },
  {
    type: 'custom',
    component: ({ data: { owner } }) => <EntityOwnerField {...owner} />,
    name: 'owner',
    label: 'Партнер',
    hideInList: true,
    hideInEdit: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Имя',
    sortable: true,
  },
  {
    type: 'date',
    name: 'birthdayDate',
    label: 'Дата рождения гида',
    filterable: true,
    hideInList: true,
  },
  {
    type: 'string',
    name: 'cityName',
    label: 'Населенный пункт',
    hideInView: true,
    hideInEdit: true,
  },
  {
    type: 'referenceSelect',
    name: 'cityId',
    label: 'Населенный пункт',
    filterable: true,
    hideInList: true,
  },
  {
    type: 'custom',
    component: EntityFieldPhone,
    name: 'phone',
    label: 'Контактный номер гида',
    options: {
      length: 23,
    },
    validator: (val: string) => validatePhone(val),
  },
  {
    type: 'string',
    name: 'email',
    label: 'E-mail гида',
  },
  {
    type: 'boolean',
    name: 'withChildren',
    label: 'Можно с детьми',
    required: true,
    hideInList: true,
  },
  {
    type: 'number',
    name: 'maxNumberOfParticipants',
    label: 'Максимальное количество участников',
    hideInList: true,
  },
  {
    type: 'number',
    name: 'costPerHour',
    label: 'Стоимость в час',
    hideInList: true,
  },
  {
    type: 'string',
    name: 'description',
    label: 'Описание',
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ value }) => <EntityImage width={200} val={value?.url ?? ''} />,
    name: 'avatar',
    label: 'Фотография гида',
    required: false,
    hideInList: true,
    hideInEdit: true,
  },
  {
    type: 'custom',
    component: ({ value }) => (
      <>
        {(Array.isArray(value) ? value : []).map((image) => (
          <EntityImage key={image} width={200} val={image?.url ?? ''} />
        ))}
      </>
    ),
    name: 'photos',
    label: 'Изображения',
    required: false,
    hideInList: true,
    hideInEdit: true,
  },
  {
    type: 'custom',
    component: ({ value }) => <>{value ? 'Скрыт' : 'Отображается'}</>,
    name: 'isHide',
    label: 'Статус отображения',
    hideInList: true,
    hideInEdit: true,
  },
  {
    type: 'select',
    name: 'travelMode',
    label: 'Способ передвижения',
    options: TRAVEL_MODE_TYPE_STATUS_NAMES,
    hideInList: true,
  },
  {
    type: 'select',
    name: 'duration',
    label: 'Максимальная продолжительность работы',
    options: DURATIONS,
    hideInList: true,
    hideInView: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    options: GUIDE_STATUS_NAMES,
    filterable: true,
    hideInEdit: true,
  },

  // block and unblock button
  {
    type: 'custom',
    name: 'blockButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => (
      <div style={{ display: 'flex', gap: '5px' }}>
        <SpecialOfferButton view={'icon'} {...props} />
        <GuideEntityButton view={'icon'} {...props} type="block" />
      </div>
    ),
  },
];
