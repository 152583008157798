import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import { EntityFieldPhone } from '~/common/components/EntityFieldPhone';
import { USER_STATUS_NAMES } from '~/pages/constants';
import PartnerEntityButton from '~/pages/partner/components/PartnerEntityButton';
import {
  CONTACT_NAME_MAX_LENGTH,
  CONTACT_NAME_MIN_LENGTH,
  CONTACT_NAME_REGEX,
  EMAIL_REGEXP,
  MAX_COMPANY_NAME,
  MIN_COMPANY_NAME,
} from '~/utils/constants';
import { validatePhone, minMax, minMaxNumber, regexpTest } from '~/utils/validators';
import { maxCommissionPercentage, minCommissionPercentage } from '~/pages/partner/constants';

export const TOURIST_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    sortable: true,
    hideInEdit: true,
  },
  {
    type: 'custom',
    component: ({ value, data }) => {
      const partner = value ?? data ?? {};
      const { firstName, middleName, lastName } = partner;
      return (
        <>
          {[firstName, middleName, lastName]
            .filter(Boolean)
            .map((u) => u.trim())
            .join(' ')}
        </>
      );
    },
    name: 'name',
    label: 'Имя',
    sortable: true,
    hideInEdit: true,
  },
  {
    type: 'string',
    name: 'firstName',
    label: 'Имя',
    hideInList: true,
    hideInView: true,
    validator: (val: string) =>
      regexpTest(CONTACT_NAME_REGEX)('Обнаружены недопустимые символы')(val) ||
      minMax(
        CONTACT_NAME_MIN_LENGTH,
        CONTACT_NAME_MAX_LENGTH
      )(`Должно быть от ${CONTACT_NAME_MIN_LENGTH} до ${CONTACT_NAME_MAX_LENGTH} символов`)(
        val?.trim()
      ),
  },
  {
    type: 'string',
    name: 'lastName',
    label: 'Фамилия',
    hideInList: true,
    hideInView: true,
    validator: (val: string) =>
      regexpTest(CONTACT_NAME_REGEX)('Обнаружены недопустимые символы')(val) ||
      minMax(
        CONTACT_NAME_MIN_LENGTH,
        CONTACT_NAME_MAX_LENGTH
      )(`Должно быть от ${CONTACT_NAME_MIN_LENGTH} до ${CONTACT_NAME_MAX_LENGTH} символов`)(
        val?.trim()
      ),
  },
  {
    type: 'string',
    name: 'email',
    label: 'E-mail',
    validator: (val: string) =>
      regexpTest(EMAIL_REGEXP)('Неверный формат адреса электронной почты')(val),
  },
  {
    type: 'custom',
    component: EntityFieldPhone,
    name: 'phone',
    label: 'Контактный номер',
    validator: (val: string) => validatePhone(val),
  },
  {
    type: 'string',
    name: 'company',
    label: 'Компания',
    hideInList: true,
    validator: (val: string) =>
      minMax(
        MIN_COMPANY_NAME,
        MAX_COMPANY_NAME
      )(`Должно быть от ${MIN_COMPANY_NAME} до ${MAX_COMPANY_NAME} символов`)(val?.trim()),
  },
  {
    type: 'number',
    name: 'commission',
    label: 'Комиссия',
    hideInList: true,
    validator: (val: number) =>
      minMaxNumber(
        minCommissionPercentage,
        maxCommissionPercentage
      )(
        `Процент комиссии должен составлять от ${minCommissionPercentage} до ${maxCommissionPercentage}`
      )(val),
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    options: USER_STATUS_NAMES,
    filterable: true,
    hideInEdit: true,
  },

  // block and unblock button
  {
    type: 'custom',
    name: 'blockButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => <PartnerEntityButton {...props} view="icon" />,
  },
];
