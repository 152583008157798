import CheckIcon from '@material-ui/icons/Check';
import React, { FC, useCallback } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { IEntityFieldProps, useEntity } from 'icerockdev-admin-toolkit';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { UserStatus } from '~/pages/constants';
import PartnerEntity from '~/pages/partner/PartnerEntity';
import styles from './styles.module.scss';

type ButtonColor = 'primary' | 'secondary';
type ButtonView = 'text' | 'icon';
type ButtonVariant = 'text' | 'outlined' | 'contained';

interface ButtonProps {
  color: ButtonColor;
  view: ButtonView;
  variant: ButtonVariant;
  id: number;
}

const unblockText = 'Разблокировать';
const blockText = 'Заблокировать';
const approveText = 'Подтвердить';

const UnblockButton: FC<ButtonProps> = ({ color, view, variant, id }) => {
  const entity = useEntity<PartnerEntity>();

  const handleClick = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      await entity.unblockItem(id || 0);
    },
    [id]
  );

  return (
    <Tooltip title={unblockText}>
      <Button
        onClick={handleClick}
        variant={variant}
        color={variant === 'contained' ? color : undefined}
      >
        {view === 'icon' ? <LockIcon color={color} /> : unblockText}
      </Button>
    </Tooltip>
  );
};

const BlockButton: FC<ButtonProps> = ({ color, view, variant, id }) => {
  const entity = useEntity<PartnerEntity>();

  const handleClick = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      await entity.blockItem(id || 0);
    },
    [id]
  );

  return (
    <Tooltip title={blockText}>
      <Button
        onClick={handleClick}
        variant={variant}
        color={variant === 'contained' ? color : undefined}
      >
        {view === 'icon' ? <LockOpenIcon color={color} /> : blockText}
      </Button>
    </Tooltip>
  );
};

const ApproveButton: FC<ButtonProps> = ({ color, view, variant, id }) => {
  const entity = useEntity<PartnerEntity>();

  const handleClick = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      entity.approveItem(id || 0);
    },
    [id]
  );

  return (
    <Tooltip title={approveText}>
      <Button
        onClick={handleClick}
        variant={variant}
        color={variant === 'contained' ? color : undefined}
      >
        {view === 'icon' ? <CheckIcon color={color} /> : approveText}
      </Button>
    </Tooltip>
  );
};

const BUTTON_TYPES = {
  block: BlockButton,
  unblock: UnblockButton,
  approve: ApproveButton,
};

interface IProps extends IEntityFieldProps {
  view: ButtonView;
}

const PartnerEntityButton: FC<IProps> = ({ data, view }) => {
  const type =
    data?.status === UserStatus.CHECKING_CONTRACT
      ? 'approve'
      : data?.status !== UserStatus.ACTIVE
      ? 'unblock'
      : 'block';
  const ButtonFromType: typeof BUTTON_TYPES[typeof type] = BUTTON_TYPES[type];
  const buttonVariant: ButtonVariant = view === 'icon' ? 'text' : 'contained';
  const buttonColor: ButtonColor = type === 'block' || type === 'unblock' ? 'secondary' : 'primary';

  return (
    <div className={styles.wrap}>
      <ButtonFromType variant={buttonVariant} color={buttonColor} view={view} id={data?.id} />
    </div>
  );
};

export default PartnerEntityButton;
