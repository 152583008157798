import {
  addPopularFn,
  blockItemsFn,
  createItemsFn,
  deletePopularFn,
  unblockItemsFn,
  updateItemsFn,
} from '~/pages/city/api';
import { CityEntity } from '~/pages/city/CityEntity';
import { CITY_FIELDS } from '~/pages/city/fields';
import { UserRoles } from '~/pages/constants';
import { deleteItemsFn, fetchItemsFn, getItemsFn } from '~/utils/api';
import { API_URLS } from '~/utils/constants';

const CITY_URL = API_URLS.city;
const TITLE = 'Города';

const City = new CityEntity({
  title: TITLE,
  viewable: true,
  editable: true,
  creatable: true,
  menu: {
    label: TITLE,
    url: '/city',
    enabled: true,
  },
  api: {
    list: { url: CITY_URL, method: 'get' },
    get: { url: CITY_URL, method: 'get' },
    update: { url: CITY_URL, method: 'put' },
    create: { url: CITY_URL, method: 'post' },
  },
  roles: [UserRoles.SUPERADMIN, UserRoles.ADMIN],
  permissions: {
    list: [UserRoles.SUPERADMIN, UserRoles.ADMIN],
  },
  fields: CITY_FIELDS,
  createItemsFn,
  fetchItemsFn,
  getItemsFn,
  updateItemsFn,
  deleteItemsFn,
  blockItemsFn,
  unblockItemsFn,
  addPopularFn,
  deletePopularFn,
});

export default City;
