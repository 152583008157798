import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import EntityImage from '~/common/components/EntityImage';
import { StayUnitKind } from '~/pages/constants';
import ButtonContainer from '~/pages/placement/components/ButtonContainer';

export const PLACEMENT_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    sortable: true,
  },
  {
    type: 'custom',
    component: ({ value }) => {
      const partner = value ?? {};
      const { firstName, middleName, lastName } = partner;
      return <>{[firstName, middleName, lastName].filter(Boolean).join(' ')}</>;
    },
    name: 'partner',
    label: 'Партнер',
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ value }) => <>{value ? 'Скрыт' : 'Отображается'}</>,
    name: 'isHide',
    label: 'Статус отображения',
    hideInList: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Название',
    sortable: true,
  },
  {
    type: 'string',
    name: 'city',
    label: 'Населенный пункт',
    hideInView: true,
    hideInEdit: true,
  },
  {
    type: 'referenceSelect',
    name: 'cityId',
    label: 'Населенный пункт',
    filterable: true,
    hideInList: true,
  },
  {
    type: 'number',
    name: 'rating',
    label: 'Рейтинг',
    sortable: true,
  },
  {
    type: 'custom',
    component: ({ value }) => <>{value ? 'Да' : 'Нет'}</>,
    name: 'specialOffer',
    label: 'Спецпредложение',
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ data }) => <EntityImage width={200} val={data?.images?.[0] ?? ''} />,
    name: 'mainImage',
    label: 'Главное изображение',
    required: false,
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ value }) => (
      <>
        {value === StayUnitKind.NIGHT ? 'Ночь' : value === StayUnitKind.DAY ? 'День' : 'Неизвестно'}
      </>
    ),
    name: 'stayUnitKind',
    label: 'Тип размещения',
    required: false,
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ data }) => (
      <ul>
        <li>Адрес: {data?.address}</li>
        <li>
          Координаты: {data?.coordinate?.lat}, {data?.coordinate?.lng}
        </li>
      </ul>
    ),
    name: 'location',
    label: 'Расположение',
    required: false,
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ data }) => (
      <ul>
        <li>E-mail: {data?.email}</li>
        <li>Телефон: {data?.phone}</li>
      </ul>
    ),
    name: 'contact',
    label: 'Контактная информация',
    required: false,
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ value }) => (
      <ul>
        {(Array.isArray(value) ? value : []).map((plan) => (
          <li>
            <div>
              Название: <b>{plan?.name}</b>
            </div>
            <div>Валюта: {plan?.currency}</div>
            {plan?.description?.length ? (
              <div>
                Описание: <p dangerouslySetInnerHTML={{ __html: plan?.description }} />
              </div>
            ) : null}
          </li>
        ))}
      </ul>
    ),
    name: 'ratePlans',
    label: 'Тарифные планы',
    required: false,
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ value }) => (
      <ul>
        {(Array.isArray(value) ? value : []).map((plan) => (
          <li>
            <div>
              Название: <b>{plan?.name}</b>
            </div>
            <div>
              Описание: <p dangerouslySetInnerHTML={{ __html: plan?.description }} />
            </div>
          </li>
        ))}
      </ul>
    ),
    name: 'services',
    label: 'Доступные услуги',
    required: false,
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ value }) => (
      <>
        {(Array.isArray(value) ? value : []).map((image) => (
          <EntityImage key={image} width={200} val={image} />
        ))}
      </>
    ),
    name: 'images',
    label: 'Изображения',
    required: false,
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ value }) => (
      <p style={{ fontFamily: 'inherit', display: 'inline-block', whiteSpace: 'pre-wrap' }}>
        {value}
      </p>
    ),
    name: 'description',
    label: 'Описание',
    required: false,
    hideInList: true,
  },
  {
    type: 'custom',
    name: 'buttonContainer',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => <ButtonContainer {...props} view={'icon'} />,
  },
];
