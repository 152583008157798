export default () => ({
  formControl: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    maxWidth: 300,
    fontSize: 13,
    lineHeight: '15px',
    color: '#151515',
    fontWeight: 700,
    marginBottom: 8,
  },
  info: {
    fontSize: 11,
    lineHeight: '14px',
    color: '#898A8D',
    marginBottom: 21,
  },
  name: {
    fontSize: 13,
    lineHeight: '15px',
    color: '#151515',
    marginBottom: 16,
  },
  upload: {
    display: 'flex',
    width: 120,
    height: 40,

    '& input': {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      cursor: 'pointer',
    },
  },
  preview: {
    maxWidth: 130,
    display: 'flex',
    justifyContent: 'flex-end',

    '& img': {
      width: '100%',
      height: '100%',
    },
  },
});
