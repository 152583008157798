import React, { FC } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { useEntity } from 'icerockdev-admin-toolkit';
import { CityEntity } from '~/pages/city/CityEntity';
import StarIcon from '@material-ui/icons/Star';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';

export interface PopularButtonButtonProps extends IEntityFieldProps {
  view: 'text' | 'icon';
}
export const PopularButton: FC<PopularButtonButtonProps> = ({ data, view }) => {
  const entity = useEntity<CityEntity>();

  const addSpecialOfferText = 'Добавить в популярное';
  const deleteSpecialOfferText = 'Убрать из популярного';
  const handleClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (data?.isPopular && data?.id) {
      await entity.deletePopular(data?.id);
    } else {
      await entity.addPopular(data?.id);
    }
  };

  if (view === 'icon') {
    return (
      <Tooltip title={`${data?.isPopular ? deleteSpecialOfferText : addSpecialOfferText}`}>
        <Button onClick={handleClick}>
          {data?.isPopular ? (
            <StarIcon color={'primary'} />
          ) : (
            <StarBorderOutlinedIcon color={'primary'} />
          )}
        </Button>
      </Tooltip>
    );
  }

  if (view === 'text') {
    return (
      <Button
        onClick={handleClick}
        variant={'contained'}
        color={data?.isPopular ? 'secondary' : 'primary'}
      >
        {data?.isPopular ? deleteSpecialOfferText : addSpecialOfferText}
      </Button>
    );
  }

  return null;
};
