import {
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityUpdateFunction,
} from 'icerockdev-admin-toolkit';
import { ExcursionStatus } from '~/pages/constants';
import { axios } from '~/utils/axios';
import { format } from 'date-fns';

const setStatusFn =
  (status: ExcursionStatus) =>
  async ({ url, token }: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
    const result = await axios.put(
      `${url}/update`,
      { status },
      {
        headers: { authorization: token },
      }
    );

    return {
      data: result.data,
    };
  };

export const blockItemsFn = setStatusFn(ExcursionStatus.DISABLED);
export const unblockItemsFn = setStatusFn(ExcursionStatus.ACTIVE);
export const approveItemsFn = setStatusFn(ExcursionStatus.ACTIVE);
export const declineItemsFn = setStatusFn(ExcursionStatus.DISABLED);
export const checkItemsFn = setStatusFn(ExcursionStatus.CHECK);

const setSpecialOfferFn =
  (state: boolean) =>
  async ({ url, token }: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
    const result = await axios.put(
      `${url}/update`,
      { isSpecial: state },
      {
        headers: { authorization: token },
      }
    );

    return {
      data: result.data,
    };
  };

export const addSpecialOfferFn = setSpecialOfferFn(true);
export const deleteSpecialOfferFn = setSpecialOfferFn(false);

export const updateItemsFn: IEntityUpdateFunction = async ({ url, token, data }) => {
  const {
    name,
    cityId,
    phone,
    email,
    travelMode,
    description,
    duration,
    birthdayDate,
    costPerHour,
    maxNumberOfParticipants,
    withChildren,
  } = data;
  const formattedDate = format(new Date(birthdayDate), 'yyyy-MM-dd');

  const result = await axios.put(
    `${url}/${data.id}`,
    {
      name,
      cityId,
      phone,
      email,
      travelMode,
      description,
      duration,
      birthdayDate: formattedDate,
      costPerHour,
      maxNumberOfParticipants,
      withChildren,
    },
    {
      headers: { authorization: token },
    }
  );

  return {
    data: result.data,
  };
};
