import { IEntityUpdateFunction } from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';

export const updateItemsFn: IEntityUpdateFunction = async ({ url, token, data }) => {
  const result = await axios.put(
    `${url}/${data.id}`,
    {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      commission: data.commission,
      company: data.company,
    },
    {
      headers: { authorization: token ?? '' },
    }
  );
  return {
    data: result.data,
  };
};
