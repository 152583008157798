import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import { USER_STATUS_NAMES } from '~/pages/constants';
import TouristEntityButton from '~/pages/tourist/components/TouristEntityButton';

export const TOURIST_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    sortable: true,
  },
  {
    type: 'custom',
    component: ({ value, data }) => {
      const tourist = value ?? data ?? {};
      const { firstName, middleName, lastName } = tourist;
      return (
        <>
          {[firstName, middleName, lastName]
            .filter(Boolean)
            .map((u) => u.trim())
            .join(' ')}
        </>
      );
    },
    name: 'name',
    label: 'Имя',
    sortable: true,
  },
  {
    type: 'string',
    name: 'email',
    label: 'E-mail',
  },
  {
    type: 'string',
    name: 'phone',
    label: 'Контактный номер',
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    options: USER_STATUS_NAMES,
    filterable: true,
  },

  // block and unblock button
  {
    type: 'custom',
    name: 'blockButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => <TouristEntityButton {...props} type="block" />,
  },
];
