import React, { FC } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import styles from './styles.module.scss';
import 'leaflet/dist/leaflet.css';

interface IProps {
  position: [number, number];
}

const EntityMap: FC<IProps> = ({ position }) => {
  // @ts-ignore
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });

  return (
    <div className={styles.wrap}>
      <MapContainer center={position} zoom={13} scrollWheelZoom={false} attributionControl={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}></Marker>
      </MapContainer>
    </div>
  );
};

export default EntityMap;
