import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import { EntityFieldDateRange } from 'src/common/components/EntityFieldDateRange';
import { TRANSACTION_STATUS_NAMES } from '~/pages/constants';

export const TRANSACTION_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    sortable: true,
  },
  {
    type: 'string',
    name: 'transactionId',
    label: 'ID транзакции',
    sortable: true,
  },
  {
    type: 'string',
    name: 'bookingNumber',
    label: 'Номер бронирования',
    hideInList: true,
    hideInView: true,
  },
  {
    type: 'string',
    name: 'email',
    label: 'E-mail',
    hideInList: true,
  },
  {
    type: 'string',
    name: 'phone',
    label: 'Контактный номер',
    hideInList: true,
  },
  {
    type: 'string',
    name: 'fullName',
    label: 'ФИО туриста',
    sortable: true,
  },
  {
    type: 'date',
    component: EntityFieldDateRange,
    name: 'date',
    label: 'Дата транзакции',
    filterable: true,
    options: { dependencyFields: ['dateFrom', 'dateTo'] },
  },
  {
    type: 'date',
    name: 'dateFrom',
    label: 'Дата начала',
    hideInList: true,
    hideInView: true,
  },
  {
    type: 'date',
    name: 'dateTo',
    label: 'Дата окончания',
    hideInList: true,
    hideInView: true,
  },
  {
    type: 'number',
    name: 'amount',
    label: 'Сумма',
  },
  {
    type: 'custom',
    component: ({ value }) => <>{value ? `${value}%` : ''}</>,
    name: 'commission',
    label: 'Комиссия',
    hideInList: true,
    hideInView: true,
  },
  {
    type: 'string',
    name: 'currency',
    label: 'Валюта',
    hideInList: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    options: TRANSACTION_STATUS_NAMES,
    filterable: true,
  },
];
