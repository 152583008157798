export enum UserRoles {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  TOURIST = 'TOURIST',
  PARTNER = 'PARTNER',
}

export enum RoleTypes {
  SUPERADMIN = 5,
  ADMIN = 10,
  PARTNER = 30,
  TOURIST = 20,
}

export enum PlacementStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  CHECK = 'CHECK',
}

export const PLACEMENT_STATUS_NAMES = {
  [PlacementStatus.ACTIVE]: 'Активен',
  [PlacementStatus.DISABLED]: 'Отклонен',
  [PlacementStatus.CHECK]: 'На проверке',
};

export enum StayUnitKind {
  NIGHT = 'NightRate',
  DAY = 'DailyRate',
}

export enum BookingStatus {
  waiting_payment = 'WAITING_PAYMENT',
  paid = 'PAID',
  cancelled = 'CANCELLED',
  waiting_partner = 'WAITING_PARTNER',
  partner_accepted = 'PARTNER_ACCEPTED',
}

export const BOOKING_STATUS_NAMES = {
  [BookingStatus.waiting_payment]: 'Ожидает оплаты',
  [BookingStatus.paid]: 'Оплачен',
  [BookingStatus.cancelled]: 'Отменен',
  [BookingStatus.waiting_partner]: 'Ожидание подтверждения',
  [BookingStatus.partner_accepted]: 'Подтверждено партнером',
};

export enum BookingType {
  INSTRUCTOR = 'INSTRUCTOR',
  TOUR = 'TOUR',
  GUIDE = 'GUIDE',
  PLACEMENT = 'PLACEMENT',
}

export const BOOKING_TYPE_NAMES = {
  [BookingType.INSTRUCTOR]: 'Инструктор',
  [BookingType.TOUR]: 'Экскурсия',
  [BookingType.GUIDE]: 'Гид',
  [BookingType.PLACEMENT]: 'Жилье',
};

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  AUTO_REGISTERED = 'AUTO_REGISTERED', // Only for tourist
  BLOCKED = 'BLOCKED',
  CHECKING_EMAIL = 'CHECKING_EMAIL',
  CHECKING_CONTRACT = 'CHECKING_CONTRACT', // Only for partner
}

export const USER_STATUS_NAMES = {
  [UserStatus.ACTIVE]: 'Активен',
  [UserStatus.AUTO_REGISTERED]: 'Активен (авторегистрация)',
  [UserStatus.BLOCKED]: 'Заблокирован',
  [UserStatus.CHECKING_EMAIL]: 'Не активирован',
  [UserStatus.CHECKING_CONTRACT]: 'Ожидает проверки',
};

export enum TransactionStatus {
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
  WAITING_FOR_CAPTURE = 'WAITING_FOR_CAPTURE',
  CANCELED = 'CANCELED',
  HOLDED = 'HOLDED',
  REFUNDED = 'REFUNDED',
}

export const TRANSACTION_STATUS_NAMES = {
  [TransactionStatus.PENDING]: 'В ожидании',
  [TransactionStatus.SUCCEEDED]: 'Успешно',
  [TransactionStatus.WAITING_FOR_CAPTURE]: 'Ожидает оплаты',
  [TransactionStatus.CANCELED]: 'Отменена',
  [TransactionStatus.HOLDED]: 'Захолдировано',
  [TransactionStatus.REFUNDED]: 'Возвращен',
};

export enum ExcursionStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  CHECK = 'CHECK',
}

export const EXCURSION_STATUS_NAMES = {
  [ExcursionStatus.ACTIVE]: 'Активна',
  [ExcursionStatus.DISABLED]: 'Заблокирована',
  [ExcursionStatus.CHECK]: 'На проверке',
};

export enum ExcursionType {
  INDIVIDUAL = 'INDIVIDUAL',
  GROUP = 'GROUP',
}

export const EXCURSION_TYPE_NAMES = {
  [ExcursionType.INDIVIDUAL]: 'Индивидуальная',
  [ExcursionType.GROUP]: 'Групповая',
};

export enum TravelModeType {
  FOOT = 'FOOT',
  CAR = 'CAR',
  BIKE = 'BIKE',
  BUS = 'BUS',
  MOTORCYCLE = 'MOTORCYCLE',
  BOAT = 'BOAT',
  MUSEUM = 'MUSEUM',
  HORSEBACK = 'HORSEBACK',
  IN_DOOR = 'IN_DOOR',
}

export const TRAVEL_MODE_TYPE_STATUS_NAMES = {
  [TravelModeType.FOOT]: 'Пешком',
  [TravelModeType.CAR]: 'На машине',
  [TravelModeType.BIKE]: 'На велосипеде',
  [TravelModeType.BUS]: 'На автобусе',
  [TravelModeType.MOTORCYCLE]: 'На мотоцикле',
  [TravelModeType.BOAT]: 'На кораблике',
  [TravelModeType.MUSEUM]: 'В музее',
  [TravelModeType.HORSEBACK]: 'Конный',
  [TravelModeType.IN_DOOR]: 'В помещении',
};

export const DURATIONS = {
  '1': '1 час',
  '1.5': '1.5 часа',
  '2': '2 часа',
  '2.5': '2.5 часа',
  '3': '3 часа',
  '3.5': '3.5 часа',
  '4': '4 часа',
  '4.5': '4.5 часа',
  '5': '5 часов',
  '5.5': '5.5 часов',
  '6': '6 часов',
  '7': '7 часов',
  '8': '8 часов',
  '9': '9 часов',
  '10': '10 часов',
  '12': '12 часов',
};

export const CLOSING_HOURS = {
  '1': 'За 1 час',
  '2': 'За 2 часа',
  '3': 'За 3 часа',
  '4': 'За 4 часа',
  '5': 'За 5 часов',
  '6': 'За 6 часов',
  '7': 'За 7 часов',
  '8': 'За 8 часов',
  '9': 'За 9 часов',
  '10': 'За 10 часов',
  '11': 'За 11 часов',
  '12': 'За 12 часов',
  '24': 'За 24 часов',
};
export enum GuideStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  CHECK = 'CHECK',
}

export const GUIDE_STATUS_NAMES = {
  [GuideStatus.ACTIVE]: 'Активен',
  [GuideStatus.DISABLED]: 'Заблокирован',
  [GuideStatus.CHECK]: 'На проверке',
};

export const TIMEZONE_STATUS_NAMES = Object.assign(
  {},
  // @ts-ignore
  ...Intl.supportedValuesOf('timeZone').map((timezone) => ({ [timezone]: timezone }))
);
