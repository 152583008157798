import { Button } from '@material-ui/core';
import {
  Entity,
  EntityFooter,
  EntityList,
  IEntityProps,
  EntityBreadcrumbs,
  EntityViewer,
  ENTITY_ERRORS,
  IEntityFetchFunctionResult,
  ENTITY_REFERENCE_FIELDS,
} from 'icerockdev-admin-toolkit';
import i18n from 'icerockdev-admin-toolkit/dist/i18n';
import { action, computed, flow, observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';
import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EmptyList from '~/common/components/EmptyList';
import { fetchItemsFn } from '~/utils/api';
import { EntityHead } from '~/common/components/EntityHead';
import { DefaultStatus } from '~/utils/constants';

export interface ICustomEntityProps extends IEntityProps {
  deleteItemsFn?: IEntityProps['getItemsFn'];
  blockItemsFn?: IEntityProps['createItemsFn'];
  unblockItemsFn?: IEntityProps['createItemsFn'];
}

class CustomEntity extends Entity {
  @observable deleteItemsFn: ICustomEntityProps['deleteItemsFn'] = undefined;

  @observable blockItemsFn: ICustomEntityProps['blockItemsFn'] = undefined;

  @observable unblockItemsFn: ICustomEntityProps['unblockItemsFn'] = undefined;

  emptyText = 'По вашему запросу ничего не найдено';

  deletionText = 'Вы подтверждаете удаление?';

  blockText = 'Вы подтверждаете блокировку?';

  unblockText = 'Вы подтверждаете разблокировку?';

  constructor(fields?: Partial<ICustomEntityProps>) {
    super();

    if (fields) {
      Object.assign(this, fields);
    }
  }

  @action
  fetchItems = () => {
    this.fetchItemsCancel();

    this.fetchItemsInstance = flow(function* (this: Entity) {
      this.isLoading = true;
      this.error = '';
      this.selected = [];

      try {
        if (!this.api?.list?.url || !this.fetchItemsFn) {
          throw new Error(i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));
        }

        const filter = this.getFilters();

        const result: IEntityFetchFunctionResult = yield this.parent?.auth?.withToken(
          this.fetchItemsFn,
          {
            url: this.api?.list?.url || '',
            filter,
            page: this.page,
            count: this.items,
            sortBy: this.sortBy,
            sortDir: this.sortDir,
          }
        );

        if (!result || result.error)
          throw new Error(result?.error || i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));

        this.data = result?.data?.list || [];
        this.filterData = result?.filterData || {};
        this.totalCount = result?.data?.totalCount || 0;

        const references = this.fields
          .filter(
            (field) =>
              field.type &&
              Object.prototype.hasOwnProperty.call(ENTITY_REFERENCE_FIELDS, field.type) &&
              this.references[field.name]?.getMany
          )
          .map(async (field) => ({
            [field.name]: await this.references[field.name].getMany(this),
          }));

        const refResults = yield Promise.all(references);

        this.referenceData = refResults.reduce(
          (obj: Record<string, any>, res: Record<string, any>) => ({
            ...obj,
            ...res,
          }),
          {}
        );

        this.fields = this.fields.map((field) =>
          this.referenceData[field.name]
            ? {
                ...field,
                options: { referenceData: this.referenceData[field.name] },
              }
            : field
        );
        this.isLoading = false;
      } catch (e: any) {
        this.parent?.notifications.showError(e?.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  blockItem = async (id: number) => {
    if (!this.parent?.auth?.withToken || !window.confirm(this.blockText)) return;

    this.isLoading = true;

    await this.parent.auth
      .withToken(this.blockItemsFn || (() => null), {
        url: `${this?.api?.update.url}/${id}`,
      })
      .catch((e: Error) => this.parent?.notifications.showError(e.message));

    await this.getItem(id);
    await this.fetchItems();

    this.isLoading = true;
  };

  @action
  unblockItem = async (id: number) => {
    if (!this.parent?.auth?.withToken || !window.confirm(this.unblockText)) return;

    this.isLoading = true;

    await this.parent.auth
      .withToken(this.unblockItemsFn || (() => null), {
        url: `${this?.api?.update.url}/${id}`,
      })
      .catch((e: Error) => this.parent?.notifications.showError(e.message));

    await this.getItem(id);
    await this.fetchItems();

    this.isLoading = true;
  };

  @action
  deleteItem = async (id: number) => {
    if (!this.parent?.auth?.withToken || !window.confirm(this.deletionText)) return;

    this.isLoading = true;
    try {
      await this.parent.auth
        .withToken(this.deleteItemsFn || (() => null), {
          url: `${this?.api?.update.url}/${id}`,
        })
        .catch((e: Error) => this.parent?.notifications.showError(e.message));

      const result = await this.parent.auth.withToken(fetchItemsFn, {
        url: this?.api?.get.url,
      });

      this.data = result.data.list;

      // eslint-disable-next-line consistent-return
      return 'success';
    } catch (e) {
      this.parent?.notifications.showError(e.message.toString());
    } finally {
      this.isLoading = false;
    }
    await this.fetchItems();
  };

  @action
  onMount = () => {
    this.getFiltersFromHash();
    reaction(
      () => [this.filters, this.sortBy, this.sortDir, this.page, this.items],
      this.setFiltersWindowHash
    );
    reaction(() => [this.items, this.sortBy, this.sortDir], this.applyFilter);
    reaction(() => this.page, this.fetchItems);
    this.fetchItems();
  };

  @computed
  get CreatorBody() {
    return observer(() => {
      if (!this.canCreate) return this.forbiddenPlaceholder;

      return (
        <EntityViewer
          fields={this.fields}
          errors={this.editorFieldErrors}
          url={this.menu.url}
          onSave={this.createItem}
          onCancel={this.onEditCancel}
          onResetFieldError={this.resetFieldError}
          isEditing
          isLoading={this.isLoading}
          setEditorData={this.setEditorData}
          data={this.editorData}
          getItem={this.createEmptyItem}
          cancelGetItem={this.getItemsCancel}
          viewable={this.viewable}
          withToken={this.parent?.auth?.withToken}
          entity={this}
        />
      );
    });
  }

  @computed
  get ViewerBody() {
    return observer(({ id }: { id: string }) => {
      if (!this.canView) return this.forbiddenPlaceholder;

      return (
        <EntityViewer
          id={id}
          fields={this.fields}
          url={this.menu.url}
          errors={this.editorFieldErrors}
          onSave={() => {}}
          onCancel={this.onEditCancel}
          onResetFieldError={this.resetFieldError}
          isEditing={false}
          isLoading={this.isLoading}
          setEditorData={this.setEditorData}
          data={this.editorData}
          getItem={this.getItem}
          cancelGetItem={this.getItemsCancel}
          withToken={this.parent?.auth?.withToken}
          viewable={this.viewable}
          entity={this}
        />
      );
    });
  }

  @computed
  get ListBody() {
    return observer(() =>
      this.data.length > 0 ? (
        <EntityList
          fields={this.fields}
          data={this.data}
          extra={this.ListExtra}
          isLoading={this.isLoading}
          url={this.menu.url}
          selected={this.selected}
          sortBy={this.sortBy}
          sortDir={this.sortDir}
          canView={this.viewable && this.canView}
          canEdit={this.editable && this.canEdit}
          canSelect={this.selectable}
          setSelected={this.setSelected}
          onSortChange={this.setSort}
          withToken={this.parent?.auth?.withToken}
          entity={this}
        />
      ) : (
        <EmptyList isLoading={this.isLoading} text={this.emptyText} />
      )
    );
  }

  @computed
  get ListHead() {
    return observer(() => (
      <EntityHead
        filterData={this.filterData}
        title={<this.ListHeadTitle />}
        buttons={<this.ListHeadButtons />}
        filters={this.filters}
        fields={this.fields}
        setFilters={this.setFilters}
        url={this.menu.url}
        applyFilter={this.applyFilter}
        withToken={this.parent?.auth?.withToken}
        onExport={this.exportData}
        canExport={this.exportable && this.canExport}
        canCreate={this.creatable && this.canCreate}
        entity={this}
        clearData={this.createEmptyItem}
      />
    ));
  }

  @computed
  get ListFooter() {
    return observer(() =>
      this.data.length > 0 ? (
        <EntityFooter
          page={this.page}
          itemsPerPage={this.itemsPerPage}
          items={this.items}
          totalCount={this.totalCount}
          setPage={this.setPage}
          setPerPage={this.setPerPage}
        />
      ) : (
        <></>
      )
    );
  }

  @computed
  get Breadcrumbs() {
    return observer(
      ({
        id,
        isEditing = false,
        isCreating = false,
        buttons,
      }: {
        id?: any;
        isEditing?: boolean;
        isCreating?: boolean;
        buttons?: ReactElement;
      }) => (
        <EntityBreadcrumbs
          data={this.editorData}
          fields={this.fields}
          id={id}
          name={this.title}
          url={this.menu.url}
          isEditing={isEditing}
          isCreating={isCreating}
          buttons={buttons}
          viewable={this.viewable}
          editable={false}
        />
      )
    );
  }

  @computed
  get ViewerHeadButtons() {
    return observer(({ id }: { id: any }) => {
      const itemStatus = this.editorData.status;

      const history = useHistory();
      const { t } = useTranslation();

      const handleBlockOrDeleteButtonClick = useCallback(async () => {
        if (itemStatus === DefaultStatus.Blocked) {
          await this.deleteItem(id || 0).then((res) => {
            if (res === 'success') {
              history.push(this.menu.url);
            }
          });
        }
        if (itemStatus === DefaultStatus.Active) {
          await this.blockItem(id || 0);
        }
      }, [id, itemStatus]);

      const handleUnblockButtonClick = useCallback(async () => {
        await this.unblockItem(id);
        await this.getItem(id);
      }, [id, itemStatus]);

      return (
        <>
          {this.editable && (
            <Button
              color="primary"
              variant="contained"
              to={`${this.menu.url}/${id}/edit`}
              component={RouterLink}
              style={{ marginRight: 16 }}
            >
              {t('buttons:Edit')}
            </Button>
          )}
          <Button
            onClick={handleBlockOrDeleteButtonClick}
            variant="contained"
            color="secondary"
            type="button"
            style={{ marginRight: 10 }}
          >
            {itemStatus !== DefaultStatus.Active ? 'Удалить' : 'Блокировать'}
          </Button>
          {itemStatus === DefaultStatus.Blocked && (
            <Button
              onClick={handleUnblockButtonClick}
              variant="contained"
              color="secondary"
              type="button"
              style={{ marginRight: 10 }}
            >
              Разблокировать
            </Button>
          )}
        </>
      );
    });
  }
}

export default CustomEntity;
