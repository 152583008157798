import { isEmail as isEmailValidator } from 'validator';
import {
  DEFAULT_MAX_STRING_LENGTH,
  DEFAULT_MIN_STRING_LENGTH,
  PHONE_NUMBER_MAX_LENGTH,
  PHONE_NUMBER_MIN_LENGTH,
  PHONE_NUMBER_REGEXP,
} from '~/utils/constants';

export const minMax =
  (min: number, max: number) =>
  (message: string) =>
  (val: string): string => {
    if (!val) return '';
    return val.trim().length < min || val.trim().length > max ? message : '';
  };

export const minMaxNumber =
  (min: number, max: number) =>
  (message: string) =>
  (val: number): string => {
    if (!val) return '';
    return val < min || val > max ? message : '';
  };

export const minMaxLength =
  (min: number, max: number) =>
  (message: string) =>
  (val: string): string =>
    !val || val.length < min || val.length > max ? message : '';

export const regexpTest =
  (regexp: RegExp) =>
  (message: string) =>
  (val: string): string => {
    if (!val) return '';
    return regexp.test(val) ? '' : message;
  };

export const isEmail =
  (message: string) =>
  (val: string): string =>
    !val || isEmailValidator(val) ? '' : message;

export const regexAndMinMaxExtendedValidator =
  (message: string | undefined) =>
  (regex: RegExp, regexError: string) =>
  (min: number, max: number, lengthError): string => {
    if (!message) {
      return '';
    }

    const errorMessage: string[] = [];

    if (!regex.test(message)) {
      errorMessage.push(regexError);
    }

    if (message.trim().length < min || message.trim().length > max) {
      errorMessage.push(lengthError);
    }

    return errorMessage.join(', ');
  };

export const defaultMinMaxValidator = (val: string): string =>
  minMax(
    DEFAULT_MIN_STRING_LENGTH,
    DEFAULT_MAX_STRING_LENGTH
  )(`Должно быть от ${DEFAULT_MIN_STRING_LENGTH} до ${DEFAULT_MAX_STRING_LENGTH} символов`)(
    val?.trim()
  );

export const validatePhone = (value: string) => {
  return (
    minMax(
      PHONE_NUMBER_MIN_LENGTH,
      PHONE_NUMBER_MAX_LENGTH
    )(`Должно быть от ${PHONE_NUMBER_MIN_LENGTH} до ${PHONE_NUMBER_MAX_LENGTH} символов`)(
      value?.trim()
    ) || regexpTest(PHONE_NUMBER_REGEXP)('Обнаружены недопустимые символы')(value)
  );
};
