import React, { FC } from 'react';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { EntityFileUpload } from '~/common/components/EntityFileUpload';
import EntityImage from '~/common/components/EntityImage';

type CityFileUploadProps = IEntityFieldProps & {
  fileNameFromValue?: string;
};

const CityFileUpload: FC<CityFileUploadProps> = ({ isEditing, ...props }) => (
  <EntityFileUpload
    {...props}
    isEditing={isEditing}
    renderImage={(val) => val && <EntityImage width={isEditing ? 200 : 100} val={val} />}
  />
);

export default CityFileUpload;
