import { UserRoles } from '~/pages/constants';
import TransactionEntity from '~/pages/transaction/TransactionEntity';
import { blockItemsFn, fetchItemsFn, getItemsFn, unblockItemsFn } from '~/utils/api';
import { API_URLS } from '~/utils/constants';
import { TRANSACTION_FIELDS } from './fields';

const TRANSACTION_URL = API_URLS.transaction;
const TITLE = 'Транзакции';

const Transaction = new TransactionEntity({
  title: TITLE,
  viewable: true,
  editable: false,
  creatable: false,
  menu: {
    label: TITLE,
    url: '/transactions',
    enabled: true,
  },
  api: {
    list: { url: TRANSACTION_URL, method: 'get' },
    get: { url: TRANSACTION_URL, method: 'get' },
    update: { url: TRANSACTION_URL, method: 'put' },
  },
  fields: TRANSACTION_FIELDS,
  roles: [UserRoles.SUPERADMIN, UserRoles.ADMIN],
  permissions: {
    list: [UserRoles.SUPERADMIN, UserRoles.ADMIN],
  },
  fetchItemsFn,
  getItemsFn,
  blockItemsFn,
  unblockItemsFn,
});

export default Transaction;
