import { Entity } from 'icerockdev-admin-toolkit';
import { UserRoles } from '~/pages/constants';
import {
  addSpecialOfferFn,
  approveItemsFn,
  checkItemsFn,
  declineItemsFn,
  deleteSpecialOfferFn,
} from '~/pages/placement/api';
import {
  blockItemsFn,
  fetchItemsFn,
  getItemsFn,
  getManyReference,
  unblockItemsFn,
} from '~/utils/api';

import { API_URLS } from '~/utils/constants';
import { PLACEMENT_FIELDS } from './fields';
import PlacementEntity from '~/pages/placement/PlacementEntity';

const PLACEMENT_URL = API_URLS.placement;
const TITLE = 'Жилье';

const Placement = new PlacementEntity({
  title: TITLE,
  viewable: true,
  editable: false,
  creatable: false,
  menu: {
    label: TITLE,
    url: '/placements',
    enabled: true,
  },
  api: {
    list: { url: PLACEMENT_URL, method: 'get' },
    get: { url: PLACEMENT_URL, method: 'get' },
    update: { url: PLACEMENT_URL, method: 'put' },
    create: { url: PLACEMENT_URL, method: 'post' },
  },
  fields: PLACEMENT_FIELDS,
  roles: [UserRoles.SUPERADMIN, UserRoles.ADMIN],
  permissions: {
    list: [UserRoles.SUPERADMIN, UserRoles.ADMIN],
  },
  fetchItemsFn,
  getItemsFn,
  blockItemsFn,
  unblockItemsFn,
  approveItemsFn,
  declineItemsFn,
  checkItemsFn,
  addSpecialOfferFn,
  deleteSpecialOfferFn,
  references: {
    cityId: {
      getMany: (entity: Entity) =>
        getManyReference({
          url: API_URLS.city,
          entity,
        }).then((result) =>
          Object.values(result).reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
        ),
    },
  },
});

export default Placement;
