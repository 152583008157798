import { IEntityProps } from 'icerockdev-admin-toolkit';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import CustomEntity, { ICustomEntityProps } from '~/common/modules/CustomEntity';
import ExcursionEntityButton from '~/pages/excursion/components/ExcursionEntityButton';
import { SpecialOfferButton } from '~/pages/excursion/components/SpecialOfferButton';

export interface IPlacementEntityProps extends ICustomEntityProps {
  approveItemsFn?: IEntityProps['createItemsFn'];
  declineItemsFn?: IEntityProps['createItemsFn'];
  checkItemsFn?: IEntityProps['createItemsFn'];
  addSpecialOfferFn?: IEntityProps['createItemsFn'];
  deleteSpecialOfferFn?: IEntityProps['createItemsFn'];
}

class ExcursionEntity extends CustomEntity {
  @observable approveItemsFn: IPlacementEntityProps['approveItemsFn'] = undefined;

  @observable declineItemsFn: IPlacementEntityProps['declineItemsFn'] = undefined;

  @observable checkItemsFn: IPlacementEntityProps['checkItemsFn'] = undefined;

  @observable addSpecialOfferFn: IPlacementEntityProps['addSpecialOfferFn'] = undefined;

  @observable deleteSpecialOfferFn: IPlacementEntityProps['deleteSpecialOfferFn'] = undefined;

  addSpecialOfferText =
    'Экскурсия будет добавлена в спецпредложения и будет отображаться на главной странице';

  deleteSpecialOfferText =
    'Экскурсия будет убрана из спецпредложений и не будет отображаться на главной странице';

  approveText = 'Подтвердить публикацию экскурсии?';

  declineText = 'Отклонить публикацию экскурсии?';

  checkText = 'Вернуть экскурсию на проверку?';

  blockText = 'Приостановить публикацию экскурсии?';

  unblockText = 'Возобновить публикацию экскурсии?';

  constructor(fields?: Partial<IPlacementEntityProps>) {
    super();

    if (fields) {
      Object.assign(this, fields);
    }
  }

  @action
  approveItem = async (id: number) => {
    if (!this.parent?.auth?.withToken || !window.confirm(this.approveText)) return;

    this.isLoading = true;

    await this.parent.auth
      .withToken(this.approveItemsFn || (() => null), {
        url: `${this?.api?.update.url}/${id}`,
      })
      .catch((e: Error) => this.parent?.notifications.showError(e.message));

    await this.getItem(id);
    await this.fetchItems();

    this.isLoading = true;
  };

  @action
  declineItem = async (id: number) => {
    if (!this.parent?.auth?.withToken || !window.confirm(this.declineText)) return;

    this.isLoading = true;

    await this.parent.auth
      .withToken(this.declineItemsFn || (() => null), {
        url: `${this?.api?.update.url}/${id}`,
      })
      .catch((e: Error) => this.parent?.notifications.showError(e.message));

    await this.getItem(id);
    await this.fetchItems();

    this.isLoading = true;
  };

  @action
  checkItem = async (id: number) => {
    if (!this.parent?.auth?.withToken || !window.confirm(this.checkText)) return;

    this.isLoading = true;

    await this.parent.auth
      .withToken(this.checkItemsFn || (() => null), {
        url: `${this?.api?.update.url}/${id}`,
      })
      .catch((e: Error) => this.parent?.notifications.showError(e.message));

    await this.getItem(id);
    await this.fetchItems();

    this.isLoading = true;
  };

  @action
  addSpecialOffer = async (id: number) => {
    if (!this.parent?.auth?.withToken || !window.confirm(this.addSpecialOfferText)) return;

    this.isLoading = true;

    await this.parent.auth
      .withToken(this.addSpecialOfferFn || (() => null), {
        url: `${this?.api?.update.url}/${id}`,
        id: id,
      })
      .catch((e: Error) => this.parent?.notifications.showError(e.message));

    await this.getItem(id);
    await this.fetchItems();

    this.isLoading = true;
  };

  @action
  deleteSpecialOffer = async (id: number) => {
    if (!this.parent?.auth?.withToken || !window.confirm(this.deleteSpecialOfferText)) return;

    this.isLoading = true;

    await this.parent.auth
      .withToken(this.deleteSpecialOfferFn || (() => null), {
        url: `${this?.api?.update.url}/${id}`,
        id: id,
      })
      .catch((e: Error) => this.parent?.notifications.showError(e.message));

    await this.getItem(id);
    await this.fetchItems();

    this.isLoading = true;
  };

  @computed
  get ViewerHeadButtons() {
    return observer(() => {
      const { editorData, fields, title } = this;

      return (
        <div style={{ display: 'flex', gap: '20px' }}>
          <SpecialOfferButton {...{ data: editorData, fields, name: title }} view="text" />
          <ExcursionEntityButton {...{ data: editorData, fields, name: title }} view="text" />
        </div>
      );
    });
  }
}

export default ExcursionEntity;
