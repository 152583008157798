import { UserRoles } from '~/pages/constants';
import PartnerEntity from '~/pages/partner/PartnerEntity';
import { blockItemsFn, fetchItemsFn, getItemsFn, unblockItemsFn } from '~/utils/api';

import { API_URLS } from '~/utils/constants';
import { TOURIST_FIELDS } from './fields';
import { updateItemsFn } from '~/pages/partner/api';

const TOURIST_URL = API_URLS.tourist;
const TITLE = 'Партнеры';

const Partner = new PartnerEntity({
  title: TITLE,
  viewable: true,
  editable: true,
  creatable: false,
  menu: {
    label: TITLE,
    url: '/partners',
    enabled: true,
  },
  api: {
    list: { url: TOURIST_URL, method: 'get' },
    get: { url: TOURIST_URL, method: 'get' },
    update: { url: TOURIST_URL, method: 'put' },
  },
  fields: TOURIST_FIELDS,
  roles: [UserRoles.SUPERADMIN, UserRoles.ADMIN],
  permissions: {
    list: [UserRoles.SUPERADMIN, UserRoles.ADMIN],
  },
  fetchItemsFn: ({ filter, ...props }) =>
    fetchItemsFn({
      filter: [{ name: 'role', value: UserRoles.PARTNER }, ...(filter ?? [])],
      ...props,
    }),
  getItemsFn,
  blockItemsFn,
  unblockItemsFn,
  updateItemsFn,
});

export default Partner;
