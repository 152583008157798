import { Button } from '@material-ui/core';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import CustomEntity from '~/common/modules/CustomEntity';
import { DefaultStatus } from '~/utils/constants';

class TouristEntity extends CustomEntity {
  @computed
  get ViewerHeadButtons() {
    return observer(({ id }: { id: any }) => {
      const itemStatus = this.editorData.status;
      const { t } = useTranslation();

      const handleBlockButtonClick = useCallback(async () => {
        await this.blockItem(id || 0);
        await this.getItem(id);
      }, [id, itemStatus]);

      const handleUnblockButtonClick = useCallback(async () => {
        await this.unblockItem(id);
        await this.getItem(id);
      }, [id, itemStatus]);

      return (
        <>
          {this.editable && (
            <Button
              color="primary"
              variant="contained"
              to={`${this.menu.url}/${id}/edit`}
              component={RouterLink}
              style={{ marginRight: 16 }}
            >
              {t('buttons:Edit')}
            </Button>
          )}
          {itemStatus === DefaultStatus.Blocked ? (
            <Button
              onClick={handleUnblockButtonClick}
              variant="contained"
              color="secondary"
              type="button"
              style={{ marginRight: 10 }}
            >
              Разблокировать
            </Button>
          ) : (
            <Button
              onClick={handleBlockButtonClick}
              variant="contained"
              color="secondary"
              type="button"
              style={{ marginRight: 10 }}
            >
              Блокировать
            </Button>
          )}
        </>
      );
    });
  }
}

export default TouristEntity;
