import { UserRoles } from '~/pages/constants';
import TouristEntity from '~/pages/tourist/TouristEntity';
import { blockItemsFn, fetchItemsFn, getItemsFn, unblockItemsFn } from '~/utils/api';

import { API_URLS } from '~/utils/constants';
import { TOURIST_FIELDS } from './fields';

const TOURIST_URL = API_URLS.tourist;
const TITLE = 'Туристы';

const Tourist = new TouristEntity({
  title: TITLE,
  viewable: true,
  editable: false,
  creatable: false,
  menu: {
    label: TITLE,
    url: '/tourists',
    enabled: true,
  },
  api: {
    list: { url: TOURIST_URL, method: 'get' },
    get: { url: TOURIST_URL, method: 'get' },
    update: { url: TOURIST_URL, method: 'put' },
  },
  fields: TOURIST_FIELDS,
  roles: [UserRoles.SUPERADMIN, UserRoles.ADMIN],
  permissions: {
    list: [UserRoles.SUPERADMIN, UserRoles.ADMIN],
  },
  fetchItemsFn: ({ filter, ...props }) =>
    fetchItemsFn({
      filter: [{ name: 'role', value: UserRoles.TOURIST }, ...(filter ?? [])],
      ...props,
    }),
  getItemsFn,
  blockItemsFn,
  unblockItemsFn,
});

export default Tourist;
