import React, { FC } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { useEntity } from 'icerockdev-admin-toolkit';
import PlacementEntity from '~/pages/placement/PlacementEntity';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { IEntityFieldProps } from 'icerockdev-admin-toolkit';

export interface HideShowButtonProps extends IEntityFieldProps {
  view: 'text' | 'icon';
}
export const HideShowButton: FC<HideShowButtonProps> = ({ data, view }) => {
  const entity = useEntity<PlacementEntity>();
  const showText = 'Открыть отображение';
  const hideText = 'Скрыть отображение';

  const handleClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (data?.isHide) {
      await entity.unblockItem(data?.id || 0);
    } else {
      await entity.blockItem(data?.id || 0);
    }
  };

  if (view === 'icon') {
    return (
      <Tooltip title={`${data?.isHide ? showText : hideText}`}>
        <Button onClick={handleClick}>
          {data?.isHide ? (
            <VisibilityOffIcon color={'primary'} />
          ) : (
            <VisibilityIcon color={'primary'} />
          )}
        </Button>
      </Tooltip>
    );
  }

  if (view === 'text') {
    return (
      <Button
        onClick={handleClick}
        variant={'contained'}
        color={data?.isHide ? 'primary' : 'secondary'}
      >
        {data?.isHide ? showText : hideText}
      </Button>
    );
  }

  return null;
};
