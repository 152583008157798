import React from 'react';
import { IEntityField } from 'icerockdev-admin-toolkit';
import EntityImage from '~/common/components/EntityImage';
import CityEntityButton from '~/pages/city/components/CityEntityButton';
import CityFileUpload from '~/pages/city/components/CityFileUpload';
import { PopularButton } from '~/pages/city/components/PopularButton';
import { TIMEZONE_STATUS_NAMES } from '~/pages/constants';
import { defaultMinMaxValidator, regexpTest } from '~/utils/validators';
import { ALLOWED_IMAGE_EXTENSIONS, CITY_NAME_REGEXP, MAX_IMAGE_SIZE } from '~/utils/constants';

export const CITY_FIELDS: IEntityField[] = [
  // Editor
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    required: false,
    sortable: true,
    hideInCreate: true,
    hideInView: true,
    hideInEdit: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Название',
    required: true,
    sortable: true,
    validator: (val: string) =>
      regexpTest(CITY_NAME_REGEXP)('Обнаружены недопустимые символы')(val) ||
      defaultMinMaxValidator(val),
  },
  {
    type: 'custom',
    component: CityFileUpload,
    name: 'image',
    label: 'Изображение',
    options: {
      maxSize: MAX_IMAGE_SIZE,
      allowedMimeType: ALLOWED_IMAGE_EXTENSIONS,
      creatorStyle: { gridColumn: 'span 1' },
      editorStyle: { gridColumn: 'span 1' },
    },
    hideInList: true,
    hideInView: true,
    required: false,
  },
  {
    type: 'custom',
    component: ({ value }) => <EntityImage width={200} val={value} />,
    name: 'imageUrl',
    label: 'Изображение',
    required: false,
    hideInList: true,
    hideInEdit: true,
    hideInCreate: true,
  },
  {
    type: 'boolean',
    name: 'isHide',
    label: 'Скрыт на сайте',
    required: true,
    hideInList: true,
  },
  {
    type: 'boolean',
    name: 'isCarousel',
    label: 'Показывать на главной странице',
    required: true,
    hideInList: true,
  },
  {
    type: 'boolean',
    name: 'isPopular',
    label: 'Отображать в популярных направлениях',
    required: true,
    hideInList: true,
  },
  {
    type: 'select',
    name: 'timezone',
    label: 'Временная зона',
    options: TIMEZONE_STATUS_NAMES,
    required: true,
    hideInList: true,
  },

  // block and unblock button
  {
    type: 'custom',
    name: 'blockButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => (
      <div style={{ display: 'flex', gap: '5px' }}>
        <PopularButton view={'icon'} {...props} />
        <CityEntityButton {...props} type="block" />
      </div>
    ),
  },
];
