import { Config } from 'icerockdev-admin-toolkit';
import Booking from '~/pages/booking';
import City from '~/pages/city';
import Excursion from '~/pages/excursion';
import Guide from '~/pages/guide';
import Partner from '~/pages/partner';
import Tourist from '~/pages/tourist';
import Transaction from '~/pages/transaction';
import auth from '../auth';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { DEFAULT_THEME } from '~/utils/theme';
import Placement from '~/pages/placement';

export default new Config({
  host: process.env.REACT_APP_BASE_URL,
  logo: '/assets/logo.svg',
  auth: auth,
  pages: [City, Placement, Excursion, Guide, Tourist, Partner, Transaction, Booking],
  i18nDefaultLanguage: 'ru',
  i18nLanguages: ['ru'],
  i18nUseBrowserLanguageDetector: false,
  theme: createMuiTheme(DEFAULT_THEME),
});
