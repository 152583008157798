import React, { FC } from 'react';

export type EntityOwnerFieldProps = {
  email: string;
  phone?: string;
  firstName: string;
  lastName: string;
  middleName?: string;
};

const EntityOwnerField: FC<EntityOwnerFieldProps> = ({
  email,
  phone,
  firstName,
  lastName,
  middleName,
}) => {
  return (
    <ul>
      <li>Имя: {[firstName, middleName, lastName].filter(Boolean).join(' ')}</li>
      <li>E-mail: {email}</li>
      {phone && <li>Телефон: {phone}</li>}
    </ul>
  );
};

export default EntityOwnerField;
