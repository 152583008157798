import BookingEntity from '~/pages/booking/BookingEntity';
import { UserRoles } from '~/pages/constants';
import { fetchItemsFn, getItemsFn } from '~/utils/api';

import { API_URLS } from '~/utils/constants';
import { BOOKING_FIELDS } from './fields';

const BOOKING_URL = API_URLS.booking;
const TITLE = 'Бронирования';

const Booking = new BookingEntity({
  title: TITLE,
  viewable: true,
  editable: false,
  creatable: false,
  menu: {
    label: TITLE,
    url: '/bookings',
    enabled: true,
  },
  api: {
    list: { url: BOOKING_URL, method: 'get' },
    get: { url: BOOKING_URL, method: 'get' },
  },
  fields: BOOKING_FIELDS,
  roles: [UserRoles.SUPERADMIN, UserRoles.ADMIN],
  permissions: {
    list: [UserRoles.SUPERADMIN, UserRoles.ADMIN],
  },
  fetchItemsFn,
  getItemsFn,
});

export default Booking;
