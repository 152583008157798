import React from 'react';
import { EntityFieldNumber, IEntityField } from 'icerockdev-admin-toolkit';
import { EntityFieldPhone } from '~/common/components/EntityFieldPhone';
import EntityImage from '~/common/components/EntityImage';
import EntityMap from '~/common/components/EntityMap';
import EntityOwnerField from '~/common/components/EntityOwnerField';
import {
  CLOSING_HOURS,
  DURATIONS,
  EXCURSION_STATUS_NAMES,
  EXCURSION_TYPE_NAMES,
  ExcursionType,
  TRAVEL_MODE_TYPE_STATUS_NAMES,
} from '~/pages/constants';
import ExcursionEntityButton from '~/pages/excursion/components/ExcursionEntityButton';
import { SpecialOfferButton } from '~/pages/excursion/components/SpecialOfferButton';
import { toJS } from 'mobx';
import { validatePhone } from '~/utils/validators';

export const EXCURSION_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    sortable: true,
    hideInEdit: true,
  },
  {
    type: 'custom',
    component: ({ data: { owner } }) => <EntityOwnerField {...owner} />,
    name: 'owner',
    label: 'Партнер',
    hideInList: true,
    hideInEdit: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'Название',
    sortable: true,
  },
  {
    type: 'custom',
    component: ({ value }) => <EntityImage width={200} val={value?.url ?? ''} />,
    name: 'mainPhoto',
    label: 'Главное изображение',
    required: false,
    hideInList: true,
    hideInEdit: true,
  },
  {
    type: 'custom',
    component: ({ value }) => (
      <>
        {(Array.isArray(value) ? value : []).map((image) => (
          <EntityImage key={image} width={200} val={image?.url ?? ''} />
        ))}
      </>
    ),
    name: 'photos',
    label: 'Изображения',
    required: false,
    hideInList: true,
    hideInEdit: true,
  },
  {
    type: 'custom',
    component: ({ value }) => <>{value ? 'Скрыт' : 'Отображается'}</>,
    name: 'isHide',
    label: 'Статус отображения',
    hideInList: true,
    hideInEdit: true,
  },
  {
    type: 'custom',
    component: ({ value }) => <>{value ? 'Да' : 'Нет'}</>,
    name: 'isSpecialOffer',
    label: 'Спецпредложение',
    hideInList: true,
    hideInEdit: true,
  },
  {
    type: 'boolean',
    name: 'withChildren',
    label: 'Можно с детьми',
    required: true,
    hideInList: true,
  },
  {
    type: 'string',
    name: 'cityName',
    label: 'Населенный пункт',
    hideInView: true,
    hideInEdit: true,
  },
  {
    type: 'referenceSelect',
    name: 'cityId',
    label: 'Населенный пункт',
    filterable: true,
    hideInList: true,
  },
  {
    type: 'string',
    name: 'guideName',
    label: 'Имя экскурсовода',
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ value }) => <EntityImage width={200} val={value?.url ?? ''} />,
    name: 'guideAvatar',
    label: 'Фото экскурсовода',
    required: false,
    hideInList: true,
    hideInEdit: true,
  },
  {
    type: 'custom',
    component: EntityFieldPhone,
    name: 'guidePhone',
    label: 'Контактный номер экскурсовода',
    validator: (val: string) => validatePhone(val),
  },
  {
    type: 'string',
    name: 'guideEmail',
    label: 'E-mail экскурсовода',
  },
  {
    type: 'select',
    name: 'travelMode',
    label: 'Способ передвижения',
    options: TRAVEL_MODE_TYPE_STATUS_NAMES,
    hideInList: true,
  },
  {
    type: 'select',
    name: 'type',
    label: 'Тип',
    options: EXCURSION_TYPE_NAMES,
    hideInList: true,
  },
  {
    type: 'select',
    name: 'duration',
    label: 'Продолжительность',
    options: DURATIONS,
    hideInList: true,
    hideInView: true,
  },
  {
    type: 'custom',
    component: ({ value }) => value && <span>{value}ч.</span>,
    name: 'duration',
    label: 'Продолжительность',
    hideInList: true,
    hideInEdit: true,
  },

  {
    type: 'custom',
    component: ({ value }) =>
      value?.lat && value?.lng ? <EntityMap position={[value.lat, value.lng]} /> : null,
    name: 'meetingPlace',
    label: 'Место встречи',
    hideInList: true,
    hideInEdit: true,
  },
  {
    type: 'string',
    name: 'meetingPlaceDescription',
    label: 'Ориентиры места встречи',
    hideInList: true,
  },
  {
    type: 'custom',
    component: ({ data }) => {
      return (
        <ul>
          <li>За взрослого: {data?.adultPrice}</li>
          {data?.withChildren && <li>За ребенка до 7ми лет: {data?.childrenPrice}</li>}
        </ul>
      );
    },
    name: 'price',
    label: 'Цена',
    hideInList: true,
    hideInEdit: true,
  },
  {
    type: 'number',
    name: 'maxNumberOfParticipants',
    label: 'Максимальное количество участников',
    hideInList: true,
    hideInView: true,
  },

  {
    type: 'select',
    name: 'registrationClosingHoursCount',
    label: 'Регистрация',
    options: CLOSING_HOURS,
    hideInList: true,
    hideInView: true,
  },
  {
    type: 'custom',
    component: ({ data }) => {
      return (
        <ul>
          <li>Время закрытия регистрации: {data?.registrationClosingHoursCount}ч.</li>
        </ul>
      );
    },
    name: 'registrationClosingHoursCount',
    label: 'Регистрация',
    hideInList: true,
    hideInEdit: true,
  },
  {
    type: 'custom',
    component: (data) => {
      const { type } = toJS(data.data);
      return (
        <EntityFieldNumber
          {...data}
          label={type === ExcursionType.GROUP ? 'Цена За взрослого' : 'Цена'}
        />
      );
    },
    name: 'adultPrice',
    label: 'Цена За взрослого',
    hideInList: true,
    hideInView: true,
  },
  {
    type: 'string',
    name: 'description',
    label: 'Описание',
    hideInList: true,
  },
  {
    type: 'select',
    name: 'status',
    label: 'Статус',
    options: EXCURSION_STATUS_NAMES,
    filterable: true,
    hideInEdit: true,
  },
  {
    type: 'custom',
    component: (data) => {
      const { withChildren, type } = toJS(data.data);
      return withChildren && type === ExcursionType.GROUP ? <EntityFieldNumber {...data} /> : <></>;
    },
    name: 'childrenPrice',
    label: 'Цена за ребенка до 7ми лет',
    hideInList: true,
    hideInView: true,
  },
  // block and unblock button
  {
    type: 'custom',
    name: 'blockButton',
    label: ' ',
    hideInEdit: true,
    hideInCreate: true,
    hideInView: true,
    component: (props) => (
      <div style={{ display: 'flex', gap: '5px' }}>
        <SpecialOfferButton view={'icon'} {...props} />
        <ExcursionEntityButton view={'icon'} {...props} type="block" />
      </div>
    ),
  },
];
