import { Entity } from 'icerockdev-admin-toolkit';
import { UserRoles } from '~/pages/constants';
import {
  addSpecialOfferFn,
  deleteSpecialOfferFn,
  checkItemsFn,
  declineItemsFn,
  approveItemsFn,
  unblockItemsFn,
  blockItemsFn,
  updateItemsFn,
} from '~/pages/guide/api';
import GuideEntity from '~/pages/guide/GuideEntity';
import { fetchItemsFn, getItemsFn, getManyReference } from '~/utils/api';
import { API_URLS } from '~/utils/constants';
import { GUIDE_FIELDS } from './fields';

const EXCURSION_URL = API_URLS.guide;
const TITLE = 'Гиды';

const Guide = new GuideEntity({
  title: TITLE,
  viewable: true,
  editable: true,
  creatable: false,
  menu: {
    label: TITLE,
    url: '/guides',
    enabled: true,
  },
  api: {
    list: { url: EXCURSION_URL, method: 'get' },
    get: { url: EXCURSION_URL, method: 'get' },
    update: { url: EXCURSION_URL, method: 'put' },
  },
  fields: GUIDE_FIELDS,
  roles: [UserRoles.SUPERADMIN, UserRoles.ADMIN],
  permissions: {
    list: [UserRoles.SUPERADMIN, UserRoles.ADMIN],
  },
  fetchItemsFn,
  getItemsFn,
  blockItemsFn,
  unblockItemsFn,
  approveItemsFn,
  declineItemsFn,
  checkItemsFn,
  addSpecialOfferFn,
  deleteSpecialOfferFn,
  updateItemsFn,
  references: {
    cityId: {
      getMany: (entity: Entity) =>
        getManyReference({
          url: API_URLS.city,
          entity,
        }).then((result) =>
          Object.values(result).reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
        ),
    },
  },
});

export default Guide;
