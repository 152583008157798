import { computed } from 'mobx';
import React from 'react';
import CustomEntity from '~/common/modules/CustomEntity';

class BookingEntity extends CustomEntity {
  @computed
  get ViewerHeadButtons() {
    return (_) => <></>;
  }
}

export default BookingEntity;
