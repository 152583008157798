import React, { FC, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { IEntityFieldProps, useEntity } from 'icerockdev-admin-toolkit';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import styles from './styles.module.scss';
import { DefaultStatus } from '~/utils/constants';
import CustomEntity from '~/common/modules/CustomEntity';

interface ButtonProps {
  color: 'primary' | 'secondary';
  id: number;
}

const UnblockButton: FC<ButtonProps> = ({ color, id }) => {
  const entity = useEntity<CustomEntity>();

  const handleClick = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      await entity.unblockItem(id || 0);
    },
    [id]
  );

  return (
    <Button onClick={handleClick}>
      <LockIcon color={color} />
    </Button>
  );
};

const BlockButton: FC<ButtonProps> = ({ color, id }) => {
  const entity = useEntity<CustomEntity>();

  const handleClick = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      await entity.blockItem(id || 0);
    },
    [id]
  );

  return (
    <Button onClick={handleClick}>
      <LockOpenIcon color={color} />
    </Button>
  );
};

const BUTTON_TYPES = {
  block: BlockButton,
  unblock: UnblockButton,
};

interface IProps extends IEntityFieldProps {
  entity: CustomEntity;
  type: keyof typeof BUTTON_TYPES;
}

const TouristEntityButton: FC<IProps> = ({ data, type }) => {
  const buttonType =
    (type === 'block' && (data?.status !== DefaultStatus.Active ? 'unblock' : 'block')) || type;
  const ButtonFromType: typeof BUTTON_TYPES[typeof type] = BUTTON_TYPES[buttonType];

  return (
    <div className={styles.wrap}>
      <ButtonFromType color="secondary" id={data?.id} />
    </div>
  );
};

export default TouristEntityButton;
