import { Button } from '@material-ui/core';
import { action, computed } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import CustomEntity from '~/common/modules/CustomEntity';
import PartnerEntityButton from '~/pages/partner/components/PartnerEntityButton';

class PartnerEntity extends CustomEntity {
  approveText = 'Вы подтверждаете активацию?';

  @action
  approveItem = async (id: number) => {
    if (!this.parent?.auth?.withToken || !window.confirm(this.approveText)) return;

    this.isLoading = true;

    await this.parent.auth
      .withToken(this.unblockItemsFn || (() => null), {
        url: `${this?.api?.update.url}/${id}`,
      })
      .catch((e: Error) => this.parent?.notifications.showError(e.message));

    await this.getItem(id);
    await this.fetchItems();

    this.isLoading = true;
  };

  @computed
  get ViewerHeadButtons() {
    return observer(({ id }: { id: any }) => {
      const { t } = useTranslation();
      const { editorData, fields, title } = this;

      return (
        <div style={{ display: 'flex', gap: '20px' }}>
          {this.editable && (
            <Button
              color="primary"
              variant="contained"
              to={`${this.menu.url}/${id}/edit`}
              component={RouterLink}
            >
              {t('buttons:Edit')}
            </Button>
          )}
          <PartnerEntityButton
            {...{ entity: this, data: editorData, fields, name: title }}
            view="text"
          />
        </div>
      );
    });
  }
}

export default PartnerEntity;
