import React, { FC, useCallback } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { IEntityFieldProps, useEntity } from 'icerockdev-admin-toolkit';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import RestoreIcon from '@material-ui/icons/Restore';
import { GuideStatus } from '~/pages/constants';
import GuideEntity from '~/pages/guide/GuideEntity';
import styles from './styles.module.scss';

type ButtonColor = 'primary' | 'secondary';
type ButtonView = 'text' | 'icon';
type ButtonVariant = 'text' | 'outlined' | 'contained';

interface ButtonProps {
  color: ButtonColor;
  view: ButtonView;
  variant: ButtonVariant;
  id: number;
}

const unblockText = 'Отобразить';
const blockText = 'Скрыть';
const approveText = 'Подтвердить';
const declineText = 'Отклонить';
const checkText = 'На проверку';

const UnblockButton: FC<ButtonProps> = ({ color, view, variant, id }) => {
  const entity = useEntity<GuideEntity>();

  const handleClick = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      await entity.unblockItem(id || 0);
    },
    [id]
  );

  return (
    <Tooltip title={unblockText}>
      <Button
        onClick={handleClick}
        variant={variant}
        color={variant === 'contained' ? color : undefined}
      >
        {view === 'icon' ? <LockIcon color={color} /> : unblockText}
      </Button>
    </Tooltip>
  );
};

const BlockButton: FC<ButtonProps> = ({ color, view, variant, id }) => {
  const entity = useEntity<GuideEntity>();

  const handleClick = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      await entity.blockItem(id || 0);
    },
    [id]
  );

  return (
    <Tooltip title={blockText}>
      <Button
        onClick={handleClick}
        variant={variant}
        color={variant === 'contained' ? color : undefined}
      >
        {view === 'icon' ? <LockOpenIcon color={color} /> : blockText}
      </Button>
    </Tooltip>
  );
};

const ApproveButton: FC<ButtonProps> = ({ color, view, variant, id }) => {
  const entity = useEntity<GuideEntity>();

  const handleClick = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      await entity.approveItem(id || 0);
    },
    [id]
  );

  return (
    <Tooltip title={approveText}>
      <Button
        onClick={handleClick}
        variant={variant}
        color={variant === 'contained' ? color : undefined}
      >
        {view === 'icon' ? <CheckIcon color={color} /> : approveText}
      </Button>
    </Tooltip>
  );
};

const DeclineButton: FC<ButtonProps> = ({ color, view, variant, id }) => {
  const entity = useEntity<GuideEntity>();

  const handleClick = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      await entity.declineItem(id || 0);
    },
    [id]
  );

  return (
    <Tooltip title={declineText}>
      <Button
        onClick={handleClick}
        variant={variant}
        color={variant === 'contained' ? color : undefined}
      >
        {view === 'icon' ? <ClearIcon color={color} /> : declineText}
      </Button>
    </Tooltip>
  );
};

const CheckButton: FC<ButtonProps> = ({ color, view, variant, id }) => {
  const entity = useEntity<GuideEntity>();

  const handleClick = useCallback(
    async (e) => {
      e.preventDefault();
      e.stopPropagation();
      await entity.checkItem(id || 0);
    },
    [id]
  );

  return (
    <Tooltip title={checkText}>
      <Button
        onClick={handleClick}
        variant={variant}
        color={variant === 'contained' ? color : undefined}
      >
        {view === 'icon' ? <RestoreIcon color={color} /> : checkText}
      </Button>
    </Tooltip>
  );
};

const ButtonType = {
  block: BlockButton,
  unblock: UnblockButton,
  approve: ApproveButton,
  decline: DeclineButton,
  check: CheckButton,
  empty: null,
};

type ButtonTypeName = keyof typeof ButtonType;

interface IProps extends IEntityFieldProps {
  view: ButtonView;
}

const GuideEntityButton: FC<IProps> = ({ data, view }) => {
  const firstButtonType: ButtonTypeName =
    data?.status == GuideStatus.CHECK
      ? 'approve'
      : data?.status == GuideStatus.ACTIVE
      ? data?.isHide
        ? 'unblock'
        : 'block'
      : 'check';

  const secondButtonType: ButtonTypeName =
    data?.status == GuideStatus.CHECK
      ? 'decline'
      : data?.status == GuideStatus.ACTIVE
      ? 'check'
      : 'empty';

  const FirstButtonFromType: typeof ButtonType[typeof firstButtonType] =
    ButtonType[firstButtonType];
  const firstButtonColor: ButtonColor =
    firstButtonType === 'block' || firstButtonType === 'unblock' ? 'secondary' : 'primary';
  const SecondButtonFromType: typeof ButtonType[typeof secondButtonType] =
    ButtonType[secondButtonType];
  const secondButtonColor: ButtonColor = secondButtonType === 'decline' ? 'secondary' : 'primary';
  const buttonVariant: ButtonVariant = view === 'icon' ? 'text' : 'contained';

  return (
    <div className={styles.wrap}>
      <FirstButtonFromType
        variant={buttonVariant}
        color={firstButtonColor}
        view={view}
        id={data?.id}
      />
      {SecondButtonFromType && (
        <SecondButtonFromType
          variant={buttonVariant}
          color={secondButtonColor}
          view={view}
          id={data?.id}
        />
      )}
    </div>
  );
};

export default GuideEntityButton;
