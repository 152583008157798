import {
  IAuthLogoutFn,
  IAuthPasswRestoreFn,
  IAuthPasswUpdateFn,
  IJWTAuthRequestFn,
  IJWTTokenRefreshFn,
  UNAUTHORIZED,
} from 'icerockdev-admin-toolkit';
import jwtDecode from 'jwt-decode';
import { RoleTypes } from '~/pages/constants';

import { axios } from '~/utils/axios';
import { API_URLS } from '~/utils/constants';
import CustomError from '~/utils/customError';

export const getAdminProfileFn = async ({ token }: { token: string }) => {
  const {
    data: { data: profile },
  } = await axios.get(API_URLS.profile, { headers: { Authorization: token } });

  return profile;
};

export const authRequestFn: IJWTAuthRequestFn = async (email: string, password: string) => {
  const {
    data: {
      data: { accessToken, refreshToken },
    },
  } = await axios.post(API_URLS.auth.signIn, { email: email.trim(), password });

  if (!accessToken || !refreshToken) {
    throw new CustomError('Error', 'Wrong login or password');
  }

  const tokenUser = jwtDecode<{ user: { roleType: number } }>(accessToken)?.user;

  if (![RoleTypes.SUPERADMIN, RoleTypes.ADMIN].includes(tokenUser?.roleType)) {
    throw new CustomError('Error', 'Wrong role');
  }

  const user = await getAdminProfileFn({ token: `Bearer ${accessToken}` });

  return {
    user,
    tokens: {
      access: accessToken,
      refresh: refreshToken,
    },
    error: '',
  };
};

export const tokenRefreshFn: IJWTTokenRefreshFn = async (refreshToken) => {
  try {
    if (!navigator.onLine) {
      const { refresh, access } = JSON.parse(localStorage.getItem('tokens') ?? '');
      return {
        access,
        refresh,
      };
    }
    const result = await axios.post(API_URLS.auth.refresh, { refreshToken });

    if (!result?.data?.data?.accessToken || !result?.data?.data?.refreshToken) {
      throw new Error(UNAUTHORIZED);
    }
    return {
      access: result.data.data.accessToken,
      refresh: result.data.data.refreshToken,
    };
  } catch (e) {
    return {
      access: '',
      refresh: '',
    };
  }
};

export const authLogoutFn: IAuthLogoutFn = async () => {
  const { access, refresh } = JSON.parse(localStorage.getItem('tokens') ?? '');

  try {
    const result = await axios.post(
      API_URLS.auth.logout,
      {
        refreshToken: refresh,
      },
      { headers: { Authorization: `Bearer ${access}` } }
    );

    if (!result.data.success) {
      throw new Error(UNAUTHORIZED);
    }

    return result;
  } catch (e: any) {
    return {
      error: e.message,
    };
  }
};

export const authPasswRestoreFn: IAuthPasswRestoreFn = async (email) => {
  try {
    const result = await axios.post(API_URLS.auth.restore, { email: email.trim() });

    if (!result?.data?.data || !(result?.data?.data?.success || result?.data?.data?.isSuccess)) {
      throw new Error(result?.data?.data?.message);
    }

    return {
      error: '',
    };
  } catch (e: any) {
    return {
      error: e.message,
    };
  }
};

export const authPasswUpdateFn: IAuthPasswUpdateFn = async (confirmationToken, password) => {
  try {
    const result = await axios.put(API_URLS.auth.restore, {
      confirmationToken,
      password,
      passwordRepeat: password,
    });

    if (!result?.data?.data || !(result?.data?.data?.success || result?.data?.data?.isSuccess)) {
      throw new Error(result?.data?.data?.message);
    }

    return {
      error: '',
    };
  } catch (e: any) {
    return {
      error: e.message,
    };
  }
};
