import { path } from 'ramda';
import { AxiosError } from 'axios';
import HttpStatusCode from 'http-status-typed';
import { UNAUTHORIZED } from 'icerockdev-admin-toolkit';

const noInternetConnection = (e: AxiosError) => {
  if (!navigator.onLine) {
    throw new Error('Network problems. Check your internet connection or log in later');
  }
  if (!e?.message) {
    throw new Error('Network problems. Try to check your internet connection or log in later');
  }
};

export const jwtExpired = (e: AxiosError): never => {
  const validationError =
    (path(['response', 'data', 'data', 0, 'message'], e) as string) ??
    (e?.response as any)?.data?.message;

  if (!validationError && e?.response?.status === HttpStatusCode.UNAUTHORIZED) {
    throw new Error(UNAUTHORIZED);
  }

  if (validationError) {
    throw new Error(validationError);
  }

  noInternetConnection(e);

  throw e;
};

export const loginError = (e: AxiosError) => {
  const validationError = path(['response', 'data', 'data', 0, 'message'], e) as string;

  if (validationError) {
    throw new Error(validationError);
  }

  noInternetConnection(e);

  throw new Error(e?.response?.data?.message);
};
