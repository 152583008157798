import { IEntityCreateFunctionProps, IEntityCreateFunctionResult } from 'icerockdev-admin-toolkit';
import { axios } from '~/utils/axios';

export const approveItemsFn = async ({
  url,
  token,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.put(
    `${url}/approve`,
    {},
    {
      headers: { authorization: token },
    }
  );

  return {
    data: result.data,
  };
};

export type IEntityDeclineFunctionProps = IEntityCreateFunctionProps & {
  comment: string;
};

export type IEntityDeclineFunctionResult = IEntityCreateFunctionResult;

export type IEntityDeclineFunction = (
  props: IEntityDeclineFunctionProps
) => Promise<IEntityDeclineFunctionResult>;

export const declineItemsFn = async ({
  url,
  comment,
  token,
}: IEntityDeclineFunctionProps): Promise<IEntityDeclineFunctionResult> => {
  const result = await axios.put(
    `${url}/decline`,
    {
      moderatorComment: comment,
    },
    {
      headers: { authorization: token },
    }
  );

  return {
    data: result.data,
  };
};

export const checkItemsFn = async ({
  url,
  token,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.put(
    `${url}/check`,
    {},
    {
      headers: { authorization: token },
    }
  );

  return {
    data: result.data,
  };
};

export type IEntitySpecialOfferFunctionResult = IEntityCreateFunctionResult;

export type IEntitySpecialOfferFunction = (
  props: IEntityCreateFunctionProps
) => Promise<IEntitySpecialOfferFunctionResult>;

export const addSpecialOfferFn = async ({
  url,
  token,
}: IEntityCreateFunctionProps): Promise<IEntitySpecialOfferFunctionResult> => {
  const result = await axios.put(
    `${url}/add`,
    {},
    {
      headers: { authorization: token },
    }
  );

  return {
    data: result.data,
  };
};

export const deleteSpecialOfferFn = async ({
  url,
  token,
}: IEntityCreateFunctionProps): Promise<IEntitySpecialOfferFunctionResult> => {
  const result = await axios.put(
    `${url}/delete`,
    {},
    {
      headers: { authorization: token },
    }
  );

  return {
    data: result.data,
  };
};
