import { Button } from '@material-ui/core';
import {
  Entity,
  IEntityProps,
  ENTITY_ERRORS,
  IEntityUpdateFunctionResult,
  IEntityCreateFunctionResult,
  IEntityUpdateFunction,
} from 'icerockdev-admin-toolkit';
import i18n from 'icerockdev-admin-toolkit/dist/i18n';
import { action, computed, flow, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import CustomEntity, { ICustomEntityProps } from '~/common/modules/CustomEntity';
import messages from '~/locales/ru/messages.json';
import { PopularButton } from '~/pages/city/components/PopularButton';

export interface ICityEntityProps
  extends Omit<ICustomEntityProps, 'blockItemsFn' | 'unblockItemsFn'> {
  deleteItemsFn?: IEntityProps['getItemsFn'];
  blockItemsFn?: IEntityProps['updateItemsFn'];
  unblockItemsFn?: IEntityProps['updateItemsFn'];
  addPopularFn?: IEntityProps['createItemsFn'];
  deletePopularFn?: IEntityProps['createItemsFn'];
}

export class CityEntity extends CustomEntity {
  @observable deleteItemsFn: ICityEntityProps['deleteItemsFn'] = undefined;

  // @ts-ignore
  @observable blockItemsFn: ICityEntityProps['blockItemsFn'] = undefined;

  // @ts-ignore
  @observable unblockItemsFn: ICityEntityProps['unblockItemsFn'] = undefined;

  @observable addPopularFn: ICityEntityProps['addPopularFn'] = undefined;

  @observable deletePopularFn: ICityEntityProps['deletePopularFn'] = undefined;

  @observable emptyText =
    'В системе пока нет созданных городов. Для начала работы с каталогом городов создайте город';

  deletionText = 'Вы уверены, что хотите удалить город?';

  blockText = 'Вы уверены, что хотите заблокировать город?';

  unblockText = 'Вы уверены, что хотите разблокировать город?';

  addPopularText = 'Город будет добавлен в популярное';

  deletePopularText = 'Город будет убран из популярного';

  constructor(fields?: Partial<ICityEntityProps>) {
    super();

    if (fields) {
      this.blockItemsFn = fields.blockItemsFn;
      Object.assign(this, fields);
    }
  }

  @action
  createItem = () => {
    this.updateItemInstance = flow(function* (this: Entity) {
      this.isLoading = true;
      this.error = '';

      try {
        const data = toJS(this.editorData);

        if (!this.validateSubmitFields(data, true)) {
          throw new Error(messages['Check all fields']);
        }

        if (!this.api?.create?.url || !this.createItemsFn) {
          throw new Error(messages['Items load failed']);
        }
        const result: IEntityCreateFunctionResult = yield this.parent?.auth?.withToken(
          this.createItemsFn,
          {
            url: this.api?.create?.url || '',
            data,
          }
        );

        if (!result || result.error)
          throw new Error(result?.error || messages['Items load failed']);
        this.fetchItems();
        this.parent?.history.push(this.menu.url);
        this.parent?.notifications.showSuccess(`Город ${result.data.name} успешно создан`);
      } catch (e) {
        this.error = e;
        this.parent?.notifications.showError(e.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  updateItem = () => {
    if (!window.confirm('Вы уверены, что хотите редактировать город?')) return;

    this.updateItemInstance = flow(function* (this: Entity) {
      this.isLoading = true;
      this.error = '';

      try {
        const data = toJS(this.editorData);

        if (!this.validateSubmitFields(data, false)) {
          throw new Error(i18n.t(ENTITY_ERRORS.INCORRECT_INPUT));
        }

        if (!this.api?.update?.url || !this.updateItemsFn) {
          throw new Error(i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));
        }

        const result: IEntityUpdateFunctionResult = yield this.parent?.auth?.withToken(
          this.updateItemsFn,
          {
            url: this.api?.update?.url || '',
            data,
          }
        );

        if (!result || result.error)
          throw new Error(result?.error || i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));

        this.fetchItems();

        if (this.parent?.history?.length && this.parent?.history.goBack) {
          this.parent?.history.goBack();
        } else if (this.parent?.history?.push) {
          this.parent?.history.push(this.menu.url);
        }
      } catch (e) {
        this.parent?.notifications.showError(e.message);
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  blockItem = async (id: number) => {
    if (!this.parent?.auth?.withToken || !window.confirm(this.blockText)) return;

    await this.blockUnblockItem(id, this.blockItemsFn);
  };

  @action
  unblockItem = async (id: number) => {
    if (!this.parent?.auth?.withToken || !window.confirm(this.unblockText)) return;

    await this.blockUnblockItem(id, this.unblockItemsFn);
  };

  @action
  private blockUnblockItem = async (id: number, func?: IEntityUpdateFunction) => {
    const blockUnblockFn = () => {
      this.updateItemInstance = flow(function* (this: CityEntity) {
        this.isLoading = true;
        this.error = '';

        try {
          const data = toJS(this.editorData);

          if (!this.validateSubmitFields(data, false)) {
            throw new Error(i18n.t(ENTITY_ERRORS.INCORRECT_INPUT));
          }

          if (!this.api?.update?.url || !func) {
            throw new Error(i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));
          }

          const result: IEntityUpdateFunctionResult = yield this.parent?.auth?.withToken(func, {
            url: this.api?.update?.url || '',
            data,
          });

          if (!result || result.error)
            throw new Error(result?.error || i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));

          this.fetchItems();
          this.getItem(id);
        } catch (e) {
          this.parent?.notifications.showError(e.message);
          this.isLoading = false;
        }
      }).bind(this)();
    };

    if (Object.keys(this.editorData).length !== 0 && this.editorData?.id === id) {
      blockUnblockFn();
    } else {
      this.getItem(id);
      this.getItemsInstance?.then(() => blockUnblockFn());
    }
  };

  @action
  addPopular = async (id: number) => {
    if (!this.parent?.auth?.withToken || !window.confirm(this.addPopularText)) return;

    await this.togglePopular(id, this.addPopularFn);
  };

  @action
  deletePopular = async (id: number) => {
    if (!this.parent?.auth?.withToken || !window.confirm(this.deletePopularText)) return;

    await this.togglePopular(id, this.deletePopularFn);
  };

  @action
  private togglePopular = async (id: number, func?: IEntityUpdateFunction) => {
    const togglePopularFn = () => {
      this.updateItemInstance = flow(function* (this: CityEntity) {
        this.isLoading = true;
        this.error = '';

        try {
          const data = toJS(this.editorData);

          if (!this.validateSubmitFields(data, false)) {
            throw new Error(i18n.t(ENTITY_ERRORS.INCORRECT_INPUT));
          }

          if (!this.api?.update?.url || !func) {
            throw new Error(i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));
          }

          const result: IEntityUpdateFunctionResult = yield this.parent?.auth?.withToken(func, {
            url: this.api?.update?.url || '',
            data,
          });

          if (!result || result.error)
            throw new Error(result?.error || i18n.t(ENTITY_ERRORS.CANT_LOAD_ITEMS));

          this.fetchItems();
          this.getItem(id);
        } catch (e) {
          this.parent?.notifications.showError(e.message);
          this.isLoading = false;
        }
      }).bind(this)();
    };

    if (Object.keys(this.editorData).length !== 0 && this.editorData?.id === id) {
      togglePopularFn();
    } else {
      this.getItem(id);
      this.getItemsInstance?.then(() => togglePopularFn());
    }
  };

  @computed
  get ViewerHeadButtons() {
    return observer(({ id }: { id: any }) => {
      const { editorData, fields, title } = this;
      const itemStatus = this.editorData.isHide;
      const { t } = useTranslation();

      const handleBlockButtonClick = useCallback(async () => {
        await this.blockItem(id ?? 0);
        await this.getItem(id ?? 0);
      }, [id, itemStatus]);

      const handleUnblockButtonClick = useCallback(async () => {
        await this.unblockItem(id);
        await this.getItem(id);
      }, [id, itemStatus]);

      return (
        <div style={{ display: 'flex', gap: '20px' }}>
          <PopularButton {...{ data: editorData, fields, name: title }} view="text" />
          <Button
            color="primary"
            variant="contained"
            to={`${this.menu.url}/${id}/edit`}
            component={RouterLink}
          >
            {t('buttons:Edit')}
          </Button>

          {itemStatus ? (
            <Button
              onClick={handleUnblockButtonClick}
              variant="contained"
              color="secondary"
              type="button"
            >
              Отображать
            </Button>
          ) : (
            <Button
              onClick={handleBlockButtonClick}
              variant="contained"
              color="secondary"
              type="button"
            >
              Скрыть
            </Button>
          )}
        </div>
      );
    });
  }
}
